export const LINE_CHART_COLOR_LIST = [
  '#729fd9',
  '#7dcc89',
  '#e8b45c',
  '#e98181',
  '#8f69d5',
  '#6cc2bd',
  '#e8814a',
  '#8e8e8e',
  '#d493af',
];

// For Wireless Signal Heatmap, this is wireless signal grade
export const HEATMAP_SNR_COLORS = {
  EXCELLENT: '#00A86B', // deep green
  GOOD: '#98D63A', // light green
  FAIR: '#FFE135', // yellow
  BAD: '#FF9944', // orange
  POOR: '#D21404', // red
  NOSIGNAL: '#B6B6B4', // gray
};

export const HEATMAP_SNR_LEGEND = [
  { label: 'Excellent', color: '#00A86B', value: '41 ~' },
  { label: 'Good', color: '#98D63A', value: '26 ~ 40' },
  { label: 'Fair', color: '#FFE135', value: '16 ~ 25' },
  { label: 'Bad', color: '#FF9944', value: '11 ~ 15' },
  { label: 'Poor', color: '#D21404', value: '10 ~' },
];

// For Network Performance Heatmap, this is network performance grade
export const HEATMAP_SCORE_COLORS = {
  EXCELLENT: '#00A86B', // deep green
  GOOD: '#98D63A', // light green
  FAIR: '#FFE135', // yellow
  BAD: '#FF9944', // orange
  POOR: '#D21404', // red
};

export const HEATMAP_SCORE_LEGEND = [
  { label: 'Excellent', color: '#00A86B', value: '0 ~ 4' },
  { label: 'Good', color: '#98D63A', value: '5 ~ 28' },
  { label: 'Fair', color: '#FFE135', value: '29 ~ 40' },
  { label: 'Bad', color: '#FF9944', value: '41 ~ 58' },
  { label: 'Poor', color: '#D21404', value: '59 ~' },
];

export const ATTENDANCE_RATE_LEGEND = [
  { label: 'Excellent', color: 'rgba(0, 168, 107, 1)', value: '~ 30%' },
  { label: 'Good', color: 'rgba(152, 214, 58, 0.8)', value: '30% ~ 50%' },
  { label: 'Fair', color: 'rgba(255, 225, 53, 0.8)', value: '50% ~ 80%' },
  { label: 'Bad', color: 'rgba(255, 153, 68, 1)', value: '80% ~ 100%' },
  { label: 'Poor', color: 'rgba(210, 20, 4, 1)', value: '100% ~' },
];

// For Network Performance BarChart, this is network performance grade
export const BAR_CHART_GRADE_COLOR = {
  EXCELLENT: '#00A86B',
  GOOD: '#98D63A',
  FAIR: '#FFE135',
  BAD: '#FF9944',
  POOR: '#D21404',
};

export const BAR_CHART_COLOR = '#0bb4ff';

export const THRESHOLD_TYPE = {
  UPPER: 'upper',
  LOWER: 'lower',
};

export const CHART_NAME = {
  TEMPERATURE_LINE_CHART: 'temperature_line_chart',
  HUMIDITY_LINE_CHART: 'humidity_line_chart',
  NOISE_LEVEL_LINE_CHART: 'noise_level_line_chart',
  CARBON_DIOXIDE_LINE_CHART: 'carbon_dioxide_line_chart',
};

export const CHART_TYPE = {
  BACKGROUND: 'background',
  VIDEO: 'video',
  BEST_EFFORT: 'besteffort',
  VOICE: 'voice',
};