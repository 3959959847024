import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { executeQuery } from 'src/helpers/request';

const buildQueryBranches = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        branch_id: ['select'],
        branch_name: ['select'],
        branch_order: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      branch_order: 'asc',
    },
    table_name: QUERY_TABLES.BRANCHES,
  };
};

const buildQueryOrganizations = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        org_id: ['select'],
        org_identifer: ['select'],
        org_name: ['select'],
        product: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.ORGANIZATIONS,
  };
};

const buildQueryMerakiNetworks = ({ orgIdentiferList }) => {
  const randomNumber =
    '######JUST_RANDOM_' + getRandomNumber900000to999999() + '######';
  orgIdentiferList = orgIdentiferList || [];
  orgIdentiferList.push(randomNumber);
  return {
    data: {
      fields: {
        network_identifer: ['select'],
        branch_id: ['select'],
        network_name: ['select'],
        org_identifer: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      org_identifer: orgIdentiferList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      network_name: 'asc',
    },
    table_name: QUERY_TABLES.MERAKI_NETWORKS,
  };
};

const buildQueryArubaBuildings = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        building_id: ['select'],
        building_name: ['select'],
        branch_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      building_name: 'asc',
    },
    table_name: QUERY_TABLES.ARUBA_BUILDINGS,
  };
};

export const fetchOrganizationList = async payload => {
  const { tenantId } = payload;
  const request = buildQueryOrganizations({ tenantId });
  return executeQuery(request);
};

export const fetchBranchList = async payload => {
  const { tenantId } = payload;
  const request = buildQueryBranches({ tenantId });
  return executeQuery(request);
};

export const fetchMerakiNetworks = async payload => {
  const { orgIdentiferList } = payload;
  const request = buildQueryMerakiNetworks({ orgIdentiferList });
  return executeQuery(request);
};

export const fetchArubaBuildings = async payload => {
  const { tenantId } = payload;
  const request = buildQueryArubaBuildings({ tenantId });
  return executeQuery(request);
};
