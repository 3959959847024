import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';
import { getRandomNumberRange } from '@/helpers/utility';
import { getRandomNumber900000to999999 } from '@/helpers/utility';

const buildQueryPeopleCountsAreaMart = ({ floorId }) => {
  const randomFloorId = getRandomNumberRange({ min: 900000, max: 999999 });
  return {
    data: {
      fields: {
        area_id: ['select'],
        area_name: ['select'],
        counts: ['select'],
        capacity_limit: ['select'],
        capacity_rate: ['select'],
        capacity_zone: ['select'],
        timestamp: ['select'],
        floor_id: ['select'],
        position_x: ['select'],
        position_y: ['select'],
        position_x1: ['select'],
        position_y1: ['select'],
        tenant_id: ['select'],
      },
      limit: null,
      skip: null,
    },
    filter: {
      floor_id: {
        in: [floorId, randomFloorId],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc'
    },
    table_name: QUERY_TABLES.PEOPLE_COUNTS_AREA_MART,
  };
};

const buildQueryPeopleCountFormat = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        people_counts_format: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.TENANTS,
  };
};

const buildQueryPeopleCountThresholds = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        good: ['select'],
        fair: ['select'],
        bad: ['select'],
        poor: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.PEOPLE_COUNTS_THRESHOLDS,
  };
};

const getPeopleCountsAreaByFloorId = async payload => {
  const { floorId } = payload;
  const request = buildQueryPeopleCountsAreaMart({ floorId });
  return executeQuery(request);
};

const fetchPeopleCountFormat = async payload => {
  const { tenantId } = payload;
  const request = buildQueryPeopleCountFormat({ tenantId });
  return executeQuery(request);
};

const fetchPeopleCountThresholds = async payload => {
  const { tenantId } = payload;
  const request = buildQueryPeopleCountThresholds({ tenantId });
  return executeQuery(request);
};

export {
  getPeopleCountsAreaByFloorId,
  fetchPeopleCountFormat,
  fetchPeopleCountThresholds,
}
