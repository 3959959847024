import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';
import { getRandomNumber900000to999999 } from '@/helpers/utility';

const buildQuerySignageComponents = ({ tenantId, userId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        branch_id: ['select'],
        component_child_id: ['select'],
        component_id: ['select'],
        display_order: ['select'],
        floor_id: ['select'],
        interval: ['select'],
        signage_components_id: ['select'],
        user_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
      user_id: [userId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.SIGNAGE_COMPONENTS,
  };
};

const buildQuerySignageComponentNotice = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        notice: ['select'],
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.SIGNAGE_NOTICES,
  };
};

const buildQueryPeopleCountFormat = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        people_counts_format: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.TENANTS,
  };
};

const buildQueryPeopleCountThresholds = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        good: ['select'],
        fair: ['select'],
        bad: ['select'],
        poor: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.PEOPLE_COUNTS_THRESHOLDS,
  };
};

const fetchSignageComponents = async payload => {
  const { tenantId, userId } = payload;
  const request = buildQuerySignageComponents({ tenantId, userId });
  return executeQuery(request);
};

const fetchSignageComponentNotice = async payload => {
  const { tenantId } = payload;
  const request = buildQuerySignageComponentNotice({ tenantId });
  return executeQuery(request);
};

const fetchPeopleCountFormat = async payload => {
  const { tenantId } = payload;
  const request = buildQueryPeopleCountFormat({ tenantId });
  return executeQuery(request);
};

const fetchPeopleCountThresholds = async payload => {
  const { tenantId } = payload;
  const request = buildQueryPeopleCountThresholds({ tenantId });
  return executeQuery(request);
};

export {
  fetchSignageComponents,
  fetchSignageComponentNotice,
  fetchPeopleCountFormat,
  fetchPeopleCountThresholds,
}
