import { COMMON_TEXT } from '@/helpers/common-text';
import { percentToPixel } from '@/helpers/utility';
import { format } from 'date-fns';
import { cloneDeep, toNumber } from 'lodash';
import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Draggable, Resizable, makeAble, makeMoveable } from 'react-moveable';

const Moveable = makeMoveable([Draggable, Resizable]);
const TOP_THRESHOLD = 60; // in pixel
const HIDE_SHOW_STATUS = {
  SHOW: 'show',
  HIDE: 'hide',
};

class AreaMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areaData: {},
      isViewOnly: false,
    };
    this.targetRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      isViewOnly: this.props.isViewOnly ?? false,
      areaData: {
        ...this.props,
        device_positions: cloneDeep(this.props.device_positions),
        rect: cloneDeep(this.props.rect),
      },
    });
  }

  componentDidUpdate(prevProps) {
    const {
      area_name: prevAreaName,
      scaleValue: prevScaleValue,
      newRect: prevNewRect,
    } = prevProps;
    const {
      area_name: currentAreaName,
      scaleValue: currentScaleValue,
      newRect: currentNewRect,
    } = this.props;
    if (
      prevAreaName !== currentAreaName ||
      prevScaleValue !== currentScaleValue ||
      prevNewRect !== currentNewRect
    ) {
      const { areaData } = this.state;
      areaData.area_name = currentAreaName;
      areaData.scaleValue = currentScaleValue;
      areaData.newRect = currentNewRect;
      this.setState({ areaData });
    }
  }

  toggleAllMoveableLineAndControl = ({ status, targetRef }) => {
    if (!targetRef || !targetRef.current) {
      return;
    }
    const hideShowStatus = status === HIDE_SHOW_STATUS.HIDE ? 'none' : 'block';
    const moveableControl = targetRef.current.getControlBoxElement();
    if (moveableControl) {
      //find all class moveable-control moveable-direction inside moveableControl
      const moveableLines = moveableControl.querySelectorAll('.moveable-line');
      if (moveableLines) {
        moveableLines.forEach(direction => {
          direction.style.display = hideShowStatus;
        });
      }
      const moveableDirections = moveableControl.querySelectorAll(
        '.moveable-control.moveable-direction'
      );
      if (moveableDirections) {
        moveableDirections.forEach(direction => {
          direction.style.display = hideShowStatus;
        });
      }
    }
  };

  updateAreaMarkerPosition = ({
    topPercent,
    leftPercent,
    widthPercent,
    heightPercent,
  }) => {
    let { areaData } = this.state;
    const newRect = {
      topPercent,
      leftPercent,
      widthPercent,
      heightPercent,
    };
    this.props.updateAreaMarker({
      area_id: areaData.area_id,
      newRectData: newRect,
    });
  };

  updateAreaMarkerStatus = ({ status }) => {
    this.props.updateAreaMarkerStatus({
      status: status,
    });
  };

  render() {
    const { areaData, isViewOnly } = this.state;
    const {
      area_name,
      area_id,
      timestamp,
      counts,
      capacity_limit,
      capacity_rate,
      capacity_zone,
      scaleValue,
    } = areaData;
    const capacityRate = capacity_rate ? (capacity_rate * 100).toFixed(0) : 0;
    const {
      imageDimension: imageDimensionRef,
      isSelected,
      isPeopleFormatCount,
    } = this.props;

    const isCount = isPeopleFormatCount ?? false;

    let isEditable = false;
    if (!isViewOnly) {
      if (isSelected) {
        isEditable = true;
        this.toggleAllMoveableLineAndControl({
          status: HIDE_SHOW_STATUS.SHOW,
          targetRef: this.targetRef,
        });
      } else {
        this.toggleAllMoveableLineAndControl({
          status: HIDE_SHOW_STATUS.HIDE,
          targetRef: this.targetRef,
        });
      }
    }

    const MouseEnterLeaveAble = makeAble('enterLeave', {
      mouseEnter(moveable) {
        // add class name abc to moveable.state.target current class name list
        moveable.state.target.classList.add('is-hovered');
        const controlBox = moveable.getControlBoxElement();
        if (controlBox && controlBox.parentElement) {
          const { top, height } = controlBox.parentElement?.style;
          if (top && height) {
            const topPercent = Number(top.replace('px', '').replace('%', ''));
            const heightPercent = Number(
              height.replace('px', '').replace('%', '')
            );
            const imgDimension =
              imageDimensionRef.current.getBoundingClientRect();
            updateAreaNameView({
              topPercent: topPercent,
              heightPercent: heightPercent,
              imageDimension: imgDimension,
              moveable,
              status: HIDE_SHOW_STATUS.SHOW,
            });
          }
        }
        // find class name image-marker using window.document
        const imageMarker = window.document.querySelector('.image-marker');
        if (imageMarker) {
          imageMarker.style.borderColor = '#0000005C';
        }
      },
      mouseLeave(moveable) {
        moveable.state.target.classList.remove('is-hovered');
        const controlBox = moveable.getControlBoxElement();
        if (controlBox && controlBox.parentElement) {
          const { top, height } = controlBox.parentElement?.style;
          if (top && height) {
            const topPercent = Number(top.replace('px', '').replace('%', ''));
            const heightPercent = Number(
              height.replace('px', '').replace('%', '')
            );
            const imgDimension =
              imageDimensionRef.current.getBoundingClientRect();
            updateAreaNameView({
              topPercent: topPercent,
              heightPercent: heightPercent,
              imageDimension: imgDimension,
              moveable,
              status: HIDE_SHOW_STATUS.HIDE,
            });
          }
        }
        // find class name image-marker using window.document
        const imageMarker = window.document.querySelector('.image-marker');
        if (imageMarker) {
          imageMarker.style.borderColor = 'transparent';
        }
      },
    });

    const DimensionViewable = {
      name: 'dimensionViewable',
      props: [],
      events: [],
      render(moveable, React) {
        const rect = moveable.getRect();
        // Add key (required)
        // Add class prefix moveable-(required)
        return (
          <div
            key={'dimension-viewer'}
            className={'moveable-dimension'}
            style={{
              left: `${rect.width / 2}px`,
              bottom: `10px`,
              willChange: 'transform',
              transform: `translate(-50%, 0px)`,
              display: 'none',
            }}
          >
            <span>{area_name}</span>
          </div>
        );
      },
    };

    const updateAreaNameView = ({
      topPercent,
      heightPercent,
      imageDimension,
      moveable,
      status,
    }) => {
      const topPixel = percentToPixel(topPercent, imageDimension.height);
      const heightPixel = percentToPixel(heightPercent, imageDimension.height);
      let dimensionViews = [];
      const controlBox = moveable.getControlBoxElement();
      if (controlBox) {
        dimensionViews = controlBox.querySelectorAll('.moveable-dimension');
        dimensionViews = dimensionViews ?? [];
      }
      if (topPixel < TOP_THRESHOLD) {
        // move dimensionview to bottom
        dimensionViews.forEach(element => {
          element.style.bottom = 'auto';
          element.style.top = `${heightPixel + 10}px`;
        });
      } else {
        // move dimensionview to top
        dimensionViews.forEach(element => {
          element.style.bottom = '10px';
          element.style.top = `auto`;
        });
      }
      let displayStatus = 'none';
      if (status === HIDE_SHOW_STATUS.SHOW) {
        displayStatus = 'block';
        controlBox.parentElement.style['z-index'] =
          toNumber(controlBox.parentElement.style['z-index']) + 1;
      } else if (status === HIDE_SHOW_STATUS.HIDE) {
        displayStatus = 'none';
        controlBox.parentElement.style['z-index'] =
          toNumber(controlBox.parentElement.style['z-index']) - 1;
      }
      if (dimensionViews) {
        dimensionViews.forEach(element => {
          element.style.display = displayStatus;
        });
      }
    };

    const parseTranslate = translateString => {
      // return 0 if translateString is not provided
      if (!translateString) {
        return { translateX: 0, translateY: 0 };
      }
      // Remove the 'translate(' and ')' parts from the string
      const withoutBrackets = translateString
        .replace('translate(', '')
        .replace(')', '');

      // Split the remaining string by comma or space to separate X and Y values
      const parts = withoutBrackets.split(/,|\s/).filter(Boolean); // Filter out any empty strings

      // Extract translateX and translateY values
      const translateX = parts[0]?.replace('px', '') || '0'; // Default to '0' if not provided
      const translateY = parts[1]?.replace('px', '') || '0'; // Default to '0' if not provided

      return { translateX: +translateX, translateY: +translateY };
    };

    const calculateNewPosition = ({ transform, imageDimension }) => {
      // Convert translateX and translateY from pixels to percentages
      const { translateX, translateY } = parseTranslate(transform);
      const scaledTranslateX = translateX * scaleValue;
      const scaledTranslateY = translateY * scaleValue;
      const translateXPercent = (scaledTranslateX / imageDimension.width) * 100;
      const translateYPercent =
        (scaledTranslateY / imageDimension.height) * 100;
      return {
        deltaXPercentage: translateXPercent,
        deltaYPercentage: translateYPercent,
      };
    };

    const markerOnClicked = (e, area_id) => {
      this.props.updateSelectedAreaMarkerId({ area_id });
    };

    const ables = [
      isViewOnly ? {} : MouseEnterLeaveAble,
      isViewOnly ? {} : DimensionViewable,
    ];

    return (
      <Fragment>
        {isViewOnly && (
          <Tooltip
            className="custom-marker-tooltip"
            target={`.area-marker-target.target.target-${area_id}`}
            baseZIndex={2000}
            autoHide={false}
            position="top"
            event="hover"
          >
            <div className="display-marker">
              <div className="display-marker__container">
                <div className="text-label">{COMMON_TEXT.AREA_NAME}</div>
                <div className="text-value">
                  <b>{area_name}</b>
                </div>
              </div>
              <div className="display-marker__container">
                <div className="text-label">{COMMON_TEXT.COUNTS}</div>
                <div className="text-value">
                  <b>{`${counts}/${capacity_limit} (${capacityRate}%)`}</b>
                </div>
              </div>
              <div className="display-marker__container">
                <div className="text-label">
                  {COMMON_TEXT.LAST_UPDATED_TIME}
                </div>
                <div className="text-value">
                  <b>{format(new Date(timestamp), 'MM月dd日 - HH:mm')}</b>
                </div>
              </div>
            </div>
          </Tooltip>
        )}
        <div
          className={`${
            isSelected ? 'is-selected' : ''
          }  area-marker-target target target-${area_id} ${
            isViewOnly ? `capacity-zone-${capacity_zone}` : ''
          }`}
          style={{}}
          onClick={e => markerOnClicked(e, area_id)}
        >
          <Fragment>
            {isViewOnly && (
              <span
                className={`${
                  isCount ? 'count-view' : 'count-view capacity-rate'
                }`}
              >
                {isCount ? counts : capacityRate}
              </span>
            )}
          </Fragment>
        </div>
        {!isViewOnly && (
          <>
            <Moveable
              ref={this.targetRef}
              useResizeObserver={true}
              useMutationObserver={true}
              target={`.area-marker-target.target.target-${area_id}`}
              ables={ables}
              dragContainer={'.image-marker'}
              origin={!isViewOnly}
              /* Resize event edges */
              edge={false}
              /* draggable */
              draggable={isEditable}
              edgeDraggable={false}
              throttleDrag={1}
              onDragStart={e => {
                this.updateAreaMarkerStatus({
                  status: 'dragging',
                });
              }}
              onDrag={({ target, transform, moveable }) => {
                const { translateX, translateY } = parseTranslate(transform);
                const scaledTranslateX = translateX / scaleValue;
                const scaledTranslateY = translateY / scaleValue;
                target.style.transform = `translate(${scaledTranslateX}px, ${scaledTranslateY}px)`;
                this.updateAreaMarkerStatus({
                  status: 'dragging',
                });
              }}
              onDragEnd={({ target, moveable }) => {
                if (target) {
                  // find parent element of target
                  let pEle = target.parentElement;
                  if (pEle) {
                    const imgDimension =
                      imageDimensionRef.current.getBoundingClientRect();
                    const newPos = calculateNewPosition({
                      transform: target.style.transform,
                      imageDimension: imgDimension,
                    });
                    const { deltaXPercentage, deltaYPercentage } = newPos;
                    const parentTopPercent = Number(
                      pEle.style.top.replace('px', '').replace('%', '')
                    );
                    const parentLeftPercent = Number(
                      pEle.style.left.replace('px', '').replace('%', '')
                    );
                    const topPercent = parentTopPercent + deltaYPercentage;
                    const leftPercent = parentLeftPercent + deltaXPercentage;
                    const { rect, newRect } = areaData;
                    let width = newRect ? newRect.width : rect.width;
                    let height = newRect ? newRect.height : rect.height;
                    target.parentElement.style.top = `${topPercent}%`;
                    target.parentElement.style.left = `${leftPercent}%`;
                    target.parentElement.style.width = `${width}%`;
                    target.parentElement.style.height = `${height}%`;
                    this.updateAreaMarkerPosition({
                      topPercent: topPercent,
                      leftPercent: leftPercent,
                      widthPercent: width,
                      heightPercent: height,
                    });
                    updateAreaNameView({
                      topPercent: topPercent,
                      heightPercent: height,
                      imageDimension: imgDimension,
                      moveable,
                    });
                  }
                  target.style.transform = 'translate(0px, 0px)';
                }
                this.updateAreaMarkerStatus({
                  status: '',
                });
              }}
              /* When resize or scale, keeps a ratio of the width, height. */
              keepRatio={false}
              preventClickDefault={true}
              /* resizable*/
              /* Only one of resizable, scalable, warpable can be used. */
              resizable={isEditable}
              throttleResize={1}
              renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
              onResizeStart={e => {
                this.updateAreaMarkerStatus({
                  status: 'resizing',
                });
              }}
              onResize={({ target, width, height, drag }) => {
                target.style.width = `${width}px`;
                target.style.height = `${height}px`;
                target.style.transform = drag.transform;
                this.updateAreaMarkerStatus({
                  status: 'resizing',
                });
              }}
              onResizeEnd={({ target, moveable }) => {
                const rect = moveable.getRect();
                // this.updateAreaMarkerPosition({ movableRect: rect });
                if (target && rect) {
                  // find parent element of target
                  let pEle = target.parentElement;
                  if (pEle) {
                    const imgDimension =
                      imageDimensionRef.current.getBoundingClientRect();
                    const newPos = calculateNewPosition({
                      transform: `translate(${rect.left}px, ${rect.top}px)`,
                      imageDimension: imgDimension,
                    });
                    // When zoomed (scale not 1.0), the image dimension is changed
                    // Need to recalculate the image height and width to proper value
                    const scaledImageHeight = imgDimension.height / scaleValue;
                    const scaledImageWidth = imgDimension.width / scaleValue;
                    const newHeightPercentage =
                      (rect.height / scaledImageHeight) * 100;
                    const newWidthPercentage =
                      (rect.width / scaledImageWidth) * 100;
                    const { deltaXPercentage, deltaYPercentage } = newPos;
                    const parentTopPercent = Number(
                      pEle.style.top.replace('px', '').replace('%', '')
                    );
                    const parentLeftPercent = Number(
                      pEle.style.left.replace('px', '').replace('%', '')
                    );
                    const topPercent = parentTopPercent + deltaYPercentage;
                    const leftPercent = parentLeftPercent + deltaXPercentage;
                    target.parentElement.style.top = `${topPercent}%`;
                    target.parentElement.style.left = `${leftPercent}%`;
                    target.parentElement.width = `${newWidthPercentage}%`;
                    target.parentElement.height = `${newHeightPercentage}%`;
                    this.updateAreaMarkerPosition({
                      topPercent: topPercent,
                      leftPercent: leftPercent,
                      widthPercent: newWidthPercentage,
                      heightPercent: newHeightPercentage,
                    });
                  }
                  target.style.transform = 'translate(0px, 0px)';
                }
                this.updateAreaMarkerStatus({
                  status: '',
                });
              }}
              useAccuratePosition={false}
              snappable={true}
              snapGridWidth={5}
              snapGridHeight={5}
              props={{
                enterLeave: true,
                dimensionViewable: true,
              }}
              padding={{ left: 2, top: 2, right: 2, bottom: 2 }}
            />
          </>
        )}
      </Fragment>
    );
  }
}

AreaMarker.propTypes = {
  isViewOnly: PropTypes.bool,
  updateAreaMarker: PropTypes.func,
  updateAreaMarkerStatus: PropTypes.func,
  updateSelectedAreaMarkerId: PropTypes.func,
  imageDimension: PropTypes.object,
  isSelected: PropTypes.bool,
  newRect: PropTypes.object,
  isPeopleFormatCount: PropTypes.bool,
};
export default AreaMarker;
