export const API_KEY_PRODUCT_TYPE = {
  // microsoft, verkada, meraki, butlr, garoon, webex, google
  MICROSOFT_OFFICE: 'microsoft',
  MICROSOFT_TEAMS: 'teams',
  VERKADA: 'verkada',
  MERAKI: 'meraki',
  BUTLR: 'butlr',
  SLACK: 'slack',
  ARUBA: 'aruba',
  GAROON: 'garoon',
  WEBEX: 'webex',
  GOOGLE: 'google',
};

export const DEVICE_CATEGORY = {
  MERAKI: 'meraki',
  VERKADA: 'verkada',
  BUTLR: 'butlr',
  WEBEX: 'webex',
  ARUBA: 'aruba',
};

export const TENANT_SCHEDULE_TOOL_TYPE = {
  GAROON: 'garoon',
  MICROSOFT: 'microsoft',
  GOOGLE: 'google',
};

export const ROOM_NOTICE_TYPE = {
  SLACK: 'slack',
  TEAMS: 'teams',
};

export const WIRELESS_CHANNEL_UTILIZATION_TYPE = {
  CHANNEL_24GHZ: '24',
  CHANNEL_5GHZ: '5',
};

export const PEOPLE_COUNT_ANALYSIS_TYPE = {
  COUNTS: 'counts',
  CAPACITY_RATE: 'capacity_rate',
};

export const SENSOR_TYPE = {
  TEMPERATURE: 'temperature',
  HUMIDITY: 'humidity',
  CARBON_DIOXIDE: 'carbon_dioxide',
  NOISE_LEVEL: 'noise_level',
};
