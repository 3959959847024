import React, { Component } from 'react';
import { getCurrentFullDateFormattedInTimeZone } from '@/helpers/utility';

class CustomCurrentTime extends Component {
  constructor(props) {
    super(props);
    this.timeInterval = null;
    this.timeRef = React.createRef();
  }

  componentDidMount() {
    this.updateTime();
    this.timeInterval = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }

  updateTime = () => {
    if (this.timeRef.current) {
      const currentTime = getCurrentFullDateFormattedInTimeZone(
        'yyyy年MM月dd日 (EEE) HH:mm'
      );
      this.timeRef.current.textContent = currentTime;
    }
  };

  render() {
    return <span ref={this.timeRef}>-</span>;
  }
}

export default CustomCurrentTime;
