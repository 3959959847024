/**
 *
 * PeopleCountSetting
 *
 */

import PeopleCountSetting from './PeopleCountSetting.jsx';
import './PeopleCountSetting.less';

export default PeopleCountSetting;
