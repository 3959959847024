import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import ImageMarker from '@/components/CustomComponent/ReactImageMarker';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  MEMORY_STORE,
  QUERY_STRING_STORE,
  SENSOR_COLOR,
  SENSOR_MARKER_RGBA_COLOR,
  SENSOR_TABLE_RGBA_COLOR,
  SENSOR_TYPE,
} from '@/helpers/constants';
import { CustomSensorMarker } from '@/helpers/sensor-marker';
import {
  isObjectEmpty,
  transformPositionToMarkerCoordinate,
} from '@/helpers/utility';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Message } from 'primereact/message';
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSensorDataNow } from './query-request';
import { isEqual } from 'lodash';

const DATA_TABLE_COLUMNS = [
  {
    field: 'device_name',
    header: COMMON_TEXT.NAME,
    align: 'left',
    headerClassName: '',
    bodyClassName: '',
    headerStyle: '',
    bodyStyle: { fontSize: '1rem' },
  },
  {
    field: SENSOR_TYPE.TEMPERATURE,
    header: (
      <>
        <span>{COMMON_TEXT.TEMPERATURE}</span>
        <br />
        <span>(℃)</span>
      </>
    ),
    align: 'right',
    alignHeader: 'center',
    headerClassName: '',
    bodyClassName: '',
    headerStyle: { width: '100px' },
    bodyStyle: { fontSize: '1rem', fontWeight: 'bold' },
  },
  {
    field: SENSOR_TYPE.HUMIDITY,
    header: (
      <>
        <span>{COMMON_TEXT.HUMIDITY}</span>
        <br />
        <span>(%)</span>
      </>
    ),
    align: 'right',
    alignHeader: 'center',
    headerClassName: '',
    bodyClassName: '',
    headerStyle: { width: '100px' },
    bodyStyle: { fontSize: '1rem', fontWeight: 'bold' },
  },
  {
    field: SENSOR_TYPE.CARBON_DIOXIDE,
    header: (
      <>
        <span>{COMMON_TEXT.CARBON_DIOXIDE}</span>
        <br />
        <span>{`(${COMMON_TEXT.CARBON_DIOXIDE_UNIT})`}</span>
      </>
    ),
    align: 'right',
    alignHeader: 'center',
    headerClassName: '',
    bodyClassName: '',
    headerStyle: { width: '136px' },
    bodyStyle: { fontSize: '1rem', fontWeight: 'bold' },
  },
  {
    field: SENSOR_TYPE.NOISE_LEVEL,
    header: (
      <>
        <span>{COMMON_TEXT.NOISE_LEVEL}</span>
        <br />
        <span>(dB)</span>
      </>
    ),
    align: 'right',
    alignHeader: 'center',
    headerClassName: '',
    bodyClassName: '',
    headerStyle: { width: '100px' },
    bodyStyle: { fontSize: '1rem', fontWeight: 'bold' },
  },
];

const SENSOR_TYPE_OPTIONS = [
  {
    label: COMMON_TEXT.TEMPERATURE,
    value: SENSOR_TYPE.TEMPERATURE,
  },
  {
    label: COMMON_TEXT.HUMIDITY,
    value: SENSOR_TYPE.HUMIDITY,
  },
  {
    label: COMMON_TEXT.CARBON_DIOXIDE,
    value: SENSOR_TYPE.CARBON_DIOXIDE,
  },
  {
    label: COMMON_TEXT.NOISE_LEVEL,
    value: SENSOR_TYPE.NOISE_LEVEL,
  },
];

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class AirQualityNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sensorData: [],
      isLoadingData: false,
      floorMapUrl: '',
      floorMapError: false,
      selectedSensorType: SENSOR_TYPE.TEMPERATURE,
      sensorDataThreshold: {},
    };
    this._isMounted = false; // Flag to track the mounted status
  }

  validateFloorMapUrl(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(url);
      img.onerror = () => reject(url);
      img.src = url;
    });
  }

  getFloorMapUrl = floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    const floorMapUrl = floor ? floor.floor_map : '';
    return floorMapUrl;
  };

  setFloorMap = async floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    const floorMapUrl = floor ? floor.floor_map : '';
    try {
      await this.validateFloorMapUrl(floorMapUrl);
      if (this._isMounted) {
        this.setState({ floorMapError: false, floorMapUrl: floorMapUrl });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ floorMapError: true, floorMapUrl: '' });
      }
    }
  };

  componentDidMount() {
    this._isMounted = true; // Set the flag to true when the component
    const { isSignageMode, signageModeData } = this.props;
    if (isSignageMode) {
      const { data, floor, selectedSensorType } = signageModeData;
      const { sensorData, sensorDataThreshold } = data;
      this.setState({
        sensorData: sensorData,
        sensorDataThreshold: sensorDataThreshold,
        selectedSensorType: selectedSensorType,
        floor,
        floorMapUrl: this.getFloorMapUrl(floor),
      });
    } else {
      const { querystring, sensorDataThreshold } = this.props;
      const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      if (floor) {
        this.setFloorMap(floor);
        this.setState(
          { floor, sensorDataThreshold: sensorDataThreshold ?? {} },
          () => {
            this.getAllData();
          }
        );
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false; // Set the flag to false when the component unmounts
  }

  componentDidUpdate(prevProps) {
    const { querystring, isSignageMode, signageModeData, sensorDataThreshold } =
      this.props;
    const {
      querystring: prevQuerystring,
      signageModeData: prevSignageModeData,
      sensorDataThreshold: prevSensorDataThreshold,
    } = prevProps;
    if (isSignageMode) {
      const { data, floor } = signageModeData;
      const { data: prevData } = prevSignageModeData;
      const { sensorData, sensorDataThreshold } = data;
      const {
        sensorData: prevSensorData,
        sensorDataThreshold: prevSensorDataThreshold,
      } = prevData;
      const isSignageModeDataChanged =
        !isEqual(sensorData, prevSensorData) ||
        !isEqual(sensorDataThreshold, prevSensorDataThreshold);
      if (isSignageModeDataChanged) {
        this.setState({
          sensorData: sensorData,
          sensorDataThreshold: sensorDataThreshold,
          floorMapUrl: this.getFloorMapUrl(floor),
        });
      }
    } else {
      const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      const prevFloor =
        prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      const isFloorChanged = floor !== prevFloor;
      if (isFloorChanged) {
        this.setFloorMap(floor);
        this.setState(
          { floor, sensorDataThreshold: sensorDataThreshold ?? {} },
          () => {
            this.getAllData();
          }
        );
      }
      const isSensorDataThresholdChanged =
        sensorDataThreshold !== prevSensorDataThreshold;
      if (isSensorDataThresholdChanged) {
        this.setState({
          sensorDataThreshold: sensorDataThreshold ?? {},
        });
      }
    }
  }

  getAllData = async () => {
    const { floor } = this.props;
    if (!floor) {
      return;
    }
    this.setState({ isLoadingData: true });
    const { sensorData } = await this.getSensorData({
      floor,
    });

    this.setState({
      isLoadingData: false,
      sensorData,
    });
  };

  getSensorData = async ({ floor }) => {
    let sensorDataRes = await fetchSensorDataNow({
      floorId: floor,
    });

    let sensorData = [];
    if (sensorDataRes && sensorDataRes.length > 0) {
      sensorData = sensorDataRes.map(item => ({
        position: {
          x: parseFloat(item?.position_x),
          y: parseFloat(item?.position_y),
        },
        data: item ?? null,
      }));
    }
    return { sensorData };
  };

  renderSensorDataSelectButton = () => {
    const { selectedSensorType } = this.state;
    return (
      <div className="card flex justify-content-center sensor-select-button">
        <SelectButton
          value={selectedSensorType}
          onChange={e => this.setState({ selectedSensorType: e.value })}
          options={SENSOR_TYPE_OPTIONS}
          allowEmpty={false}
        />
      </div>
    );
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  columnBodyTemplate = (e, colData) => {
    let value = e[colData.field] ?? 0;
    if (
      colData.field === SENSOR_TYPE.NOISE_LEVEL ||
      colData.field === SENSOR_TYPE.HUMIDITY ||
      colData.field === SENSOR_TYPE.TEMPERATURE
    ) {
      // convert value to float number with 1 decimal places
      value = parseFloat(value).toFixed(1);
    }
    const colorStr = e[`${colData.field}_color`];
    const colorValue = e[`${colData.field}_table_color`];
    let textColor = 'black';
    if (
      colorStr &&
      (colorStr === SENSOR_COLOR.GREEN ||
        colorStr === SENSOR_COLOR.RED ||
        colorStr === SENSOR_COLOR.BLUE ||
        colorStr === SENSOR_COLOR.DEEP_BLUE)
    ) {
      textColor = 'white';
    }
    return (
      <div
        className={`column-color column-color-${colorStr ?? 'none'}`}
        style={{ backgroundColor: colorValue, color: textColor }}
      >
        <span>{`${value}`}</span>
      </div>
    );
  };

  updateColumnColor = ({ sensorDataList, sensorThresholds }) => {
    if (isObjectEmpty(sensorThresholds)) {
      return sensorDataList;
    }
    const sensorTypeList = SENSOR_TYPE_OPTIONS.map(item => item.value);
    sensorDataList.forEach(sensorData => {
      for (const sensorType of sensorTypeList) {
        const sensorValue = sensorData[sensorType];
        const tableColorKey = `${sensorType}_table_color`;
        const markerColorKey = `${sensorType}_marker_color`;
        const poorLow = sensorThresholds[`${sensorType}_poor_low`];
        const fairLow = sensorThresholds[`${sensorType}_fair_low`];
        const badLow = sensorThresholds[`${sensorType}_bad_low`];
        const fairHigh = sensorThresholds[`${sensorType}_fair_high`];
        const poorHigh = sensorThresholds[`${sensorType}_poor_high`];
        const badHigh = sensorThresholds[`${sensorType}_bad_high`];

        if (sensorValue <= poorLow || sensorValue >= poorHigh) {
          if (
            sensorValue <= poorLow &&
            sensorType === SENSOR_TYPE.TEMPERATURE
          ) {
            sensorData[`${sensorType}_color`] = SENSOR_COLOR.DEEP_BLUE;
            sensorData[tableColorKey] = SENSOR_TABLE_RGBA_COLOR.DEEP_BLUE;
            sensorData[markerColorKey] = SENSOR_MARKER_RGBA_COLOR.DEEP_BLUE;
          } else {
            sensorData[`${sensorType}_color`] = SENSOR_COLOR.RED;
            sensorData[tableColorKey] = SENSOR_TABLE_RGBA_COLOR.RED;
            sensorData[markerColorKey] = SENSOR_MARKER_RGBA_COLOR.RED;
          }
        } else if (
          (sensorValue > poorLow && sensorValue <= badLow) ||
          (sensorValue >= badHigh && sensorValue < poorHigh)
        ) {
          if (
            sensorValue > poorLow &&
            sensorValue <= badLow &&
            sensorType === SENSOR_TYPE.TEMPERATURE
          ) {
            sensorData[`${sensorType}_color`] = SENSOR_COLOR.BLUE;
            sensorData[tableColorKey] = SENSOR_TABLE_RGBA_COLOR.BLUE;
            sensorData[markerColorKey] = SENSOR_MARKER_RGBA_COLOR.BLUE;
          } else {
            sensorData[`${sensorType}_color`] = SENSOR_COLOR.ORANGE;
            sensorData[tableColorKey] = SENSOR_TABLE_RGBA_COLOR.ORANGE;
            sensorData[markerColorKey] = SENSOR_MARKER_RGBA_COLOR.ORANGE;
          }
        } else if (
          (sensorValue > badLow && sensorValue <= fairLow) ||
          (sensorValue >= fairHigh && sensorValue < badHigh)
        ) {
          if (
            sensorValue > badLow &&
            sensorValue <= fairLow &&
            sensorType === SENSOR_TYPE.TEMPERATURE
          ) {
            sensorData[`${sensorType}_color`] = SENSOR_COLOR.LIGHT_BLUE;
            sensorData[tableColorKey] = SENSOR_TABLE_RGBA_COLOR.LIGHT_BLUE;
            sensorData[markerColorKey] = SENSOR_MARKER_RGBA_COLOR.LIGHT_BLUE;
          } else {
            sensorData[`${sensorType}_color`] = SENSOR_COLOR.YELLOW;
            sensorData[tableColorKey] = SENSOR_TABLE_RGBA_COLOR.YELLOW;
            sensorData[markerColorKey] = SENSOR_MARKER_RGBA_COLOR.YELLOW;
          }
        } else {
          sensorData[`${sensorType}_color`] = SENSOR_COLOR.GREEN;
          sensorData[tableColorKey] = SENSOR_TABLE_RGBA_COLOR.GREEN;
          sensorData[markerColorKey] = SENSOR_MARKER_RGBA_COLOR.GREEN;
        }
      }
    });
    return sensorDataList;
  };

  render() {
    const {
      isLoadingData,
      floorMapUrl,
      floorMapError,
      sensorData,
      selectedSensorType,
      sensorDataThreshold,
    } = this.state;
    const { isSignageMode, CurrentTimeComponent } = this.props;

    let sensorDataTable = sensorData.map(item => {
      return item['data'];
    });
    // update color for each column
    sensorDataTable = this.updateColumnColor({
      sensorDataList: sensorDataTable,
      sensorThresholds: sensorDataThreshold,
    });

    const sensorDataMarkers = sensorDataTable?.map(item => {
      const { position_x, position_y } = item;
      const marker = transformPositionToMarkerCoordinate({
        x: position_x,
        y: position_y,
      });
      return {
        ...marker,
        item: item,
        keyItem: selectedSensorType,
      };
    });

    const isFloorMapError = !floorMapUrl || floorMapError;
    return (
      <div className="network-container air-quality-now">
        {isSignageMode && (
          <div className="text-left">
            <div className="title-text">
              {
                SENSOR_TYPE_OPTIONS.find(
                  item => item.value === selectedSensorType
                )?.label
              }
            </div>
            <div className="custom-current-time">
              <CurrentTimeComponent />
            </div>
          </div>
        )}
        <div className="network-content">
          {this.renderMessageError() || (
            <>
              <div className="map-table-container">
                {isLoadingData ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <div className="map-container">
                      {!isSignageMode && (
                        <div className="sensor-type-select-button">
                          {this.renderSensorDataSelectButton()}
                        </div>
                      )}
                      {isFloorMapError ? (
                        <>
                          <Message
                            severity="error"
                            className="mt-3 mb-3"
                            text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
                          />
                        </>
                      ) : (
                        <>
                          <ImageMarker
                            extraClass="custom-image-marker"
                            bufferLeft={0}
                            bufferTop={0}
                            src={floorMapUrl}
                            markerComponent={CustomSensorMarker}
                            markers={
                              floorMapError ? [] : sensorDataMarkers || []
                            }
                            alt=""
                          />
                        </>
                      )}
                    </div>
                    <div className="table-container">
                      <DataTable
                        className="sensor-data-table"
                        value={sensorDataTable}
                        tableStyle={{ width: '100%', maxWidth: '1200px' }}
                        size="small"
                        stripedRows
                        rowHover
                        scrollable
                        scrollHeight="700px"
                        showGridlines
                        emptyMessage={() => {
                          return (
                            <div className="flex justify-content-center align-items-center">
                              <Message
                                severity="secondary"
                                text={COMMON_TEXT.NO_DATA}
                              />
                            </div>
                          );
                        }}
                      >
                        {DATA_TABLE_COLUMNS.map((colData, i) => (
                          <Column
                            key={colData.field}
                            field={colData.field}
                            header={colData.header}
                            sortable
                            align={colData.align}
                            alignHeader={colData.alignHeader}
                            headerClassName={colData.headerClassName}
                            bodyClassName={colData.bodyClassName}
                            headerStyle={colData.headerStyle}
                            bodyStyle={colData.bodyStyle}
                            body={e => this.columnBodyTemplate(e, colData)}
                          />
                        ))}
                      </DataTable>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

AirQualityNow.propTypes = {};

export default AirQualityNow;
