import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { executeQuery } from 'src/helpers/request';

const buildQueryPeopleCountFormat = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        people_counts_format: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.TENANTS,
  };
};

const buildQueryPeopleCountThresholds = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        good: ['select'],
        fair: ['select'],
        bad: ['select'],
        poor: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.PEOPLE_COUNTS_THRESHOLDS,
  };
};


const fetchPeopleCountFormat = async payload => {
  const { tenantId } = payload;
  const request = buildQueryPeopleCountFormat({ tenantId });
  return executeQuery(request);
};

const fetchPeopleCountThresholds = async payload => {
  const { tenantId } = payload;
  const request = buildQueryPeopleCountThresholds({ tenantId });
  return executeQuery(request);
};

export {
  fetchPeopleCountFormat,
  fetchPeopleCountThresholds,
}

