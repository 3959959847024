import { MEMORY_STORE } from '@/helpers/constants';
import { getFirstComponentValue } from '@/helpers/utility';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

// 1	EMPLOYEE_LOCATIONS
// 2	ROOM_AVAILABILITIES
// 3	PEOPLE_COUNTS
// 4	PERFORMANCES
// 5	ENVIRONMENTS
// 6	MEETING_ROOMS
// 7	DEVICE_STATUS
// 8	MERAKI_LOGGING
// 9	VERKADA_LOGGING
// 10	EMPLOYEE_MANAGEMENTS
// 11	PEOPLE_COUNTS_ANALYSIS
// 12 EMPLOYEE_ATTENDANCE
// 13	SIGNAGE_COMPONENTS
// 14	BCP_OVERVIEW
const NAVIGATION_TYPE = {
  1: '/employee-locations',
  2: '/meeting-room-usage',
  3: '/people-counts',
  4: '/performances',
  5: '/air-quality',
  6: '/meeting-room-outlook-usage',
  7: '/device-status-overview',
  8: '/meraki-network-events',
  9: '/verkada-network-events',
  10: '/employee-access-log',
  11: '/people-counts-analysis',
  12: '/employee-attendance',
  13: '/signage-components',
  14: '/bcp-overview',
};

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class RedirectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNavigate: '',
    };
  }

  componentDidMount() {
    const tenants = this.props.sessionStore[MEMORY_STORE.TENANTS];
    if (!tenants || tenants.length === 0) {
      return;
    }
    this.updatePageNavigate({ tenant: tenants[0] });
  }

  componentDidUpdate(prevProps) {
    const tenants = this.props.sessionStore[MEMORY_STORE.TENANTS];
    if (!tenants || tenants.length === 0) {
      return;
    }
    this.updatePageNavigate({ tenant: tenants[0] });
  }

  updatePageNavigate = ({ tenant }) => {
    if (!tenant || !tenant.components) {
      return;
    }
    const firstValueComponent = getFirstComponentValue(tenant.components, 1);

    this.setState({ pageNavigate: firstValueComponent });
  };

  render() {
    const { pageNavigate } = this.state;
    if (!pageNavigate) {
      return <></>;
    }

    return <Redirect to={NAVIGATION_TYPE[pageNavigate]} exact={true} />;
  }
}

RedirectPage.propTypes = {};

export default RedirectPage;
