import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';
import { getRandomNumber900000to999999 } from '@/helpers/utility';

const buildQueryTenant = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        tenant_name: ['select'],
        allow_supervisor: ['select'],
        business_hour_begin: ['select'],
        components: ['select'],
        holidays: ['select'],
        schedule_tool: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.TENANTS,
  };
};

const buildRequestQueryBranch = tenantId => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        branch_id: ['select'],
        branch_name: ['select'],
        tenant_id: ['select'],
        branch_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      branch_order: 'asc',
    },
    table_name: QUERY_TABLES.BRANCHES,
  };
};

const buildRequestQueryFloor = branchIdList => {
  const randomId = getRandomNumber900000to999999();
  branchIdList = branchIdList || [];
  branchIdList.push(randomId);
  return {
    data: {
      fields: {
        floor_id: ['select'],
        floorplan_id: ['select'],
        floor_name: ['select'],
        branch_id: ['select'],
        floor_map: ['select'],
        floor_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      branch_id: branchIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      floor_order: 'asc',
    },
    table_name: QUERY_TABLES.FLOORS,
  };
};

const buildRequestVerkadaDevices = ({ floorId }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        device_type: ['select'],
        door_type: ['select'],
        floor_id: ['select'],
        model: ['select'],
        org_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.VERKADA_DEVICES,
  };
};

const buildRequestMerakiNetworks = ({ branchId }) => {
  const randomBranchId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        network_identifer: ['select'],
        branch_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      branch_id: [branchId, randomBranchId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.MERAKI_NETWORKS,
  };
};

const buildRequestMerakiDevicesByNetworkId = ({ networkIdList }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        model: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      network_identifer: [...networkIdList, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.MERAKI_DEVICES,
  };
};

const buildRequestButlrDevices = ({ tenantId }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        room_id: ['select'],
        room_name: ['select'],
        apikey_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.BUTLR_ROOMS,
  };
};

const buildRequestWebexDevices = ({ tenantId }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        workspace_id: ['select'],
        name: ['select'],
        capacity: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.WEBEX_WORKSPACES,
  };
};

const buildRequestArubaBuildings = ({ branchId }) => {
  const randomBranchId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        building_id: ['select'],
        branch_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      branch_id: [branchId, randomBranchId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.ARUBA_BUILDINGS,
  };
};

const buildRequestArubaDevices = ({ buildingIdList }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        building_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      building_id: [...buildingIdList, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.ARUBA_DEVICES,
  };
};

const buildRequestDevicePosition = floorId => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_position_id: ['select'],
        floor_id: ['select'],
        position_x: ['select'],
        position_y: ['select'],
        room_name: ['select'],
        shortening_method: ['select'],
        shortening_threshold_1: ['select'],
        shortening_threshold_2: ['select'],
        cancel_method: ['select'],
        cancel_threshold_1: ['select'],
        cancel_threshold_2: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.DEVICE_POSITIONS,
  };
};

const buildQueryGaroonFacilitiesByTenantId = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        facility_id: ['select'],
        facility_identifer: ['select'],
        name: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.GAROON_FACILITIES,
  };
};

export const fetchBranchListByTenent = async tenentId => {
  const request = buildRequestQueryBranch(tenentId);
  return executeQuery(request);
};

export const fetchFloorListByBranchIdList = async branchIdList => {
  const request = buildRequestQueryFloor(branchIdList);
  return executeQuery(request);
};

export const fetchDevicePositionByFloorId = async ({ floorId }) => {
  const request = buildRequestDevicePosition(floorId);
  return executeQuery(request);
};

export const fetchMerakiNetworkByBranchId = async payload => {
  const { branchId } = payload;
  const request = buildRequestMerakiNetworks({ branchId });
  return executeQuery(request);
};

export const fetchMerakiDevicesByNetWorkId = async payload => {
  const { networkIdList } = payload;
  const request = buildRequestMerakiDevicesByNetworkId({ networkIdList });
  return executeQuery(request);
};

export const fetchVerkadaDevicesByFloorId = async payload => {
  const { floorId } = payload;
  const request = buildRequestVerkadaDevices({ floorId });
  return executeQuery(request);
};

export const fetchButlrDevicesByTenantId = async payload => {
  const { tenantId } = payload;
  const request = buildRequestButlrDevices({ tenantId });
  return executeQuery(request);
};

export const fetchWebexDevicesByTenantId = async payload => {
  const { tenantId } = payload;
  const request = buildRequestWebexDevices({ tenantId });
  return executeQuery(request);
};

export const fetchArubaBuildingByBranchId = async payload => {
  const { branchId } = payload;
  const request = buildRequestArubaBuildings({ branchId });
  return executeQuery(request);
};

export const fetchArubaDevicesByBuildingId = async payload => {
  const { buildingIdList } = payload;
  const request = buildRequestArubaDevices({ buildingIdList });
  return executeQuery(request);
};

export const fetchTenantInfo = async payload => {
  const { tenantId } = payload;
  const request = buildQueryTenant({ tenantId });
  return executeQuery(request);
};

export const fetchGaroonFacilitiesByTenantId = async payload => {
  const { tenantId } = payload;
  const request = buildQueryGaroonFacilitiesByTenantId({ tenantId });
  return executeQuery(request);
};
