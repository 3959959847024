/**
 *
 * SignageComponentNotice
 *
 */

import SignageComponentNotice from './SignageComponentNotice.jsx';
import './SignageComponentNotice.less';

export default SignageComponentNotice;
