import { DEVICE_CATEGORY, DEVICE_MARKER_COLOR } from '@/helpers/constants';
import React from 'react';

export default function DeviceMarker(props) {
  const { size, disabled, deviceinfo } = props;
  const deviceCategory = deviceinfo?.deviceCategory ?? '';
  let color = '#dddddd';
  switch (deviceCategory) {
    case DEVICE_CATEGORY.MERAKI:
      color = DEVICE_MARKER_COLOR.MERAKI;
      break;
    case DEVICE_CATEGORY.VERKADA:
      color = DEVICE_MARKER_COLOR.VERKADA;
      break;
    case DEVICE_CATEGORY.BUTLR:
      color = DEVICE_MARKER_COLOR.BUTLR;
      break;
    case DEVICE_CATEGORY.WEBEX:
      color = DEVICE_MARKER_COLOR.WEBEX;
      break;
    case DEVICE_CATEGORY.ARUBA:
      color = DEVICE_MARKER_COLOR.ARUBA;
      break;
    default:
      break;
  }
  let iconSize = 36;
  if (size) {
    iconSize = size;
  }
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="none"
        stroke="#555555"
        strokeWidth="2"
        d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7Z"
      ></path>
      <path
        fill={disabled ? '#dddddd' : color}
        d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7Z"
      ></path>
    </svg>
  );
}
