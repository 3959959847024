import CustomDropdown from '@/components/CustomComponent/CustomDropdown/CustomDropdown';
import DeviceMarker from '@/components/CustomComponent/DeviceMarker';
import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import ImageMarker from '@/components/CustomComponent/ReactImageMarker';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  DEVICE_CATEGORY,
  EXECUTION_ID_TIMEOUT,
  MEMORY_STORE,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SHORTENING_METHOD,
  CANCEL_METHOD,
  TENANT_SCHEDULE_TOOL_TYPE,
} from '@/helpers/constants';
import {
  generateRandomString,
  getConfigurationUrlWithParam,
  transformPositionToMarkerCoordinate,
} from '@/helpers/utility';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { cloneDeep, isEmpty, isEqual, isNull } from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { RadioButton } from 'primereact/radiobutton';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchBranchListByTenent,
  fetchFloorListByBranchIdList,
  fetchGaroonFacilitiesByTenantId,
} from './query-request';
import {
  addDevicePositionToDevices,
  getArubaDevices,
  getButlrDevices,
  getDevicePositionsByFloor,
  getMerakiDevices,
  getTenantInfo,
  getVerkadaDevices,
  getWebexDevices,
  setDevicePositionToMap,
  updateDeviceLocationInfo,
} from './service';

const UPDATE_DEVICE_POSITION_STATUS = {
  DELETED: 'DELETED',
  MODIFIED: 'MODIFIED',
  NEW: 'NEW',
};

const ALLOW_INPUT_ROOM_NAME_TOOL = [
  TENANT_SCHEDULE_TOOL_TYPE.GAROON,
  TENANT_SCHEDULE_TOOL_TYPE.MICROSOFT,
  TENANT_SCHEDULE_TOOL_TYPE.GOOGLE,
];

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class DeviceLocationSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      isUpdating: false,
      merakiNetworkList: [],
      branchList: [],
      floorList: [],
      verkadaDevicesList: [],
      merakiDevicesList: [],
      butlrDevicesList: [],
      webexDevicesList: [],
      arubaDevicesList: [],
      isLoadingDevice: false,
      deviceMarkerPositionList: [],
      selectedFloor: null,
      floorMapUrl: '',
      floorMapError: false,
      isFloorMapLoading: false,
      isUpdateDialogVisible: false,
      updateDataPayload: { values: [] },
      selectedDeviceCategory: DEVICE_CATEGORY.MERAKI,
      isMeetingRoomDialogVisible: false,
      selectedMeetingRoomDevice: null,
      currentMeetingRoomDeviceValue: '',
      tenantInfo: {},
      garoonFacilityOptions: [],
      shorteningThreshold1Title: '',
      currentShorteningMethodValue: '',
      currentShorteningThreshold1: null,
      currentShorteningThreshold2: null,
      showShorteningThreshold1: false,
      showShorteningThreshold2: false,
      cancelThreshold1Title: '',
      currentCancelMethodValue: '',
      currentCancelThreshold1: null,
      currentCancelThreshold2: null,
      showCancelThreshold1: false,
      showCancelThreshold2: false,
    };

    this.deviceCategoryOptions = [
      { label: 'Meraki', value: DEVICE_CATEGORY.MERAKI },
      { label: 'Verkada', value: DEVICE_CATEGORY.VERKADA },
      { label: 'Butlr', value: DEVICE_CATEGORY.BUTLR },
      { label: 'Webex', value: DEVICE_CATEGORY.WEBEX },
      { label: 'Aruba', value: DEVICE_CATEGORY.ARUBA },
    ];

    this.deviceShorteningMethodOptions = [
      {
        label: COMMON_TEXT.DEVICE_LOCATION_SHORTENING_METHOD_DISABLED,
        value: SHORTENING_METHOD.DISABLED,
      },
      {
        label: COMMON_TEXT.DEVICE_LOCATION_SHORTENING_METHOD_SILENCE,
        value: SHORTENING_METHOD.SILENCE,
      },
      {
        label: COMMON_TEXT.DEVICE_LOCATION_SHORTENING_METHOD_REPLY_SHORTENING,
        value: SHORTENING_METHOD.REPLY_SHORTENING,
      },
      {
        label: COMMON_TEXT.DEVICE_LOCATION_SHORTENING_METHOD_REPLY_KEEP,
        value: SHORTENING_METHOD.REPLY_KEEP,
      },
    ];

    this.deviceCancelMethodOptions = [
      {
        label: COMMON_TEXT.DEVICE_LOCATION_CANCEL_METHOD_DISABLED,
        value: CANCEL_METHOD.DISABLED,
      },
      {
        label: COMMON_TEXT.DEVICE_LOCATION_CANCEL_METHOD_SILENCE,
        value: CANCEL_METHOD.SILENCE,
      },
      {
        label: COMMON_TEXT.DEVICE_LOCATION_CANCEL_METHOD_REPLY_CANCEL,
        value: CANCEL_METHOD.REPLY_CANCEL,
      },
      {
        label: COMMON_TEXT.DEVICE_LOCATION_CANCEL_METHOD_REPLY_KEEP,
        value: CANCEL_METHOD.REPLY_KEEP,
      },
    ];
    this.toastRef = React.createRef();
    this.deviceStateMap = {};

    this.timeOptions = Array.from({ length: 12 }, (_, i) => ({
      label: `${(i + 1) * 5}分`,
      value: (i + 1) * 5,
    }));
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.setState({ deviceList: [] });
        this.getData();
      });
    }
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button has-shadow"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getData = async () => {
    this.setState({
      isLoading: true,
      isFloorMapLoading: true,
      isLoadingDevice: true,
    });

    const tenantInfo = await getTenantInfo({ tenantId: this.state.tenant });
    const { schedule_tool } = tenantInfo;
    let garoonFacilityOptions = [];
    if (schedule_tool === TENANT_SCHEDULE_TOOL_TYPE.GAROON) {
      garoonFacilityOptions = await this.getGaroonFacilityOptions({
        tenantId: this.state.tenant,
      });
    }

    await fetchBranchListByTenent(this.state.tenant).then(branchList => {
      if (branchList && branchList.length > 0) {
        this.setState({ branchList });
        const branchIdList = this.state.branchList.map(
          branch => branch.branch_id
        );
        fetchFloorListByBranchIdList(branchIdList).then(floorList => {
          const sortedFloorListByBranchOrder = floorList.sort(
            (a, b) =>
              branchIdList.indexOf(a.branch_id) -
              branchIdList.indexOf(b.branch_id)
          );
          if (floorList && floorList.length > 0) {
            this.setState({
              floorList: sortedFloorListByBranchOrder,
              selectedFloor: sortedFloorListByBranchOrder[0],
            });
            this.getFloorMapUrl(this.state.selectedFloor.floor_id);
            this.getDeviceList({
              selectedFloor: this.state.selectedFloor,
              selectedTenantId: this.state.tenant,
            });
          }
        });
      }
    });

    this.setState({
      isLoading: false,
      tenantInfo,
      garoonFacilityOptions,
    });
  };

  getGaroonFacilityOptions = async ({ tenantId }) => {
    let facilities = await fetchGaroonFacilitiesByTenantId({
      tenantId: tenantId,
    });
    facilities = facilities || [];
    let result = facilities.map(facility => {
      return {
        label: facility.name,
        value: facility.facility_identifer,
      };
    });
    result.unshift({ label: 'なし', value: '' });
    return result;
  };

  getFloorMapUrl = floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    this.validateFloorMapUrl(
      floor?.floor_map,
      () => {
        this.setState({
          floorMapUrl: floor?.floor_map,
          floorMapError: false,
          isFloorMapLoading: false,
        });
      },
      () => {
        this.setState({
          floorMapUrl: '',
          floorMapError: false,
          isFloorMapLoading: false,
        });
      }
    );
  };

  validateFloorMapUrl(url, successCallback, errorCallback) {
    var img = new Image();
    img.onload = function () {
      successCallback(url);
    };
    img.onerror = function () {
      errorCallback(url);
    };
    img.src = url;
  }

  getDeviceList = async ({ selectedFloor, selectedTenantId }) => {
    this.setState({ isLoadingDevice: true });
    const floorId = selectedFloor?.floor_id;
    const branchId = selectedFloor?.branch_id;
    const deviceStateByFloorId = this.retrieveSavedDeviceStateByFloorId({
      floorId,
    });
    let verkadaDevicesList = [];
    let merakiDevicesList = [];
    let butlrDevicesList = [];
    let webexDevicesList = [];
    let arubaDevicesList = [];
    let deviceMarkerPositionList = [];

    if (deviceStateByFloorId) {
      verkadaDevicesList = cloneDeep(deviceStateByFloorId.verkadaDevicesList);
      merakiDevicesList = cloneDeep(deviceStateByFloorId.merakiDevicesList);
      butlrDevicesList = cloneDeep(deviceStateByFloorId.butlrDevicesList);
      webexDevicesList = cloneDeep(deviceStateByFloorId.webexDevicesList);
      arubaDevicesList = cloneDeep(deviceStateByFloorId.arubaDevicesList);
      deviceMarkerPositionList = cloneDeep(
        deviceStateByFloorId.deviceMarkerPositionList
      );
    } else {
      const devicePositions = await getDevicePositionsByFloor({
        floorId: floorId,
      });
      let verkadaList = await getVerkadaDevices({ floorId: floorId });
      let merakiList = await getMerakiDevices({ branchId: branchId });
      let butlrList = await getButlrDevices({ tenantId: selectedTenantId });
      let webexList = await getWebexDevices({ tenantId: selectedTenantId });
      let arubaList = await getArubaDevices({ branchId: branchId });
      verkadaList = addDevicePositionToDevices({
        devices: verkadaList,
        deviceCategory: DEVICE_CATEGORY.VERKADA,
        devicePositions,
      });
      merakiList = addDevicePositionToDevices({
        devices: merakiList,
        deviceCategory: DEVICE_CATEGORY.MERAKI,
        devicePositions,
      });
      butlrList = addDevicePositionToDevices({
        devices: butlrList,
        deviceCategory: DEVICE_CATEGORY.BUTLR,
        devicePositions,
      });
      webexList = addDevicePositionToDevices({
        devices: webexList,
        deviceCategory: DEVICE_CATEGORY.WEBEX,
        devicePositions,
      });
      arubaList = addDevicePositionToDevices({
        devices: arubaList,
        deviceCategory: DEVICE_CATEGORY.ARUBA,
        devicePositions,
      });
      verkadaDevicesList = cloneDeep(verkadaList);
      merakiDevicesList = cloneDeep(merakiList);
      butlrDevicesList = cloneDeep(butlrList);
      webexDevicesList = cloneDeep(webexList);
      arubaDevicesList = cloneDeep(arubaList);
      deviceMarkerPositionList.push(
        ...setDevicePositionToMap({ deviceList: merakiDevicesList })
      );
      deviceMarkerPositionList.push(
        ...setDevicePositionToMap({ deviceList: verkadaDevicesList })
      );
      deviceMarkerPositionList.push(
        ...setDevicePositionToMap({ deviceList: butlrDevicesList })
      );
      deviceMarkerPositionList.push(
        ...setDevicePositionToMap({ deviceList: webexDevicesList })
      );
      deviceMarkerPositionList.push(
        ...setDevicePositionToMap({ deviceList: arubaDevicesList })
      );

      const initialDeviceMarkerPositionList = cloneDeep(
        deviceMarkerPositionList.map(item => {
          return {
            device_identifer: item.device.device_identifer,
            position: item.device.position,
          };
        })
      );
      this.deviceStateMap[floorId] = {
        deviceMarkerPositionList,
        initialDeviceMarkerPositionList,
        merakiDevicesList,
        verkadaDevicesList,
        butlrDevicesList,
        webexDevicesList,
        arubaDevicesList,
      };
    }

    this.setState({
      verkadaDevicesList,
      merakiDevicesList,
      butlrDevicesList,
      webexDevicesList,
      arubaDevicesList,
      deviceMarkerPositionList,
      isLoadingDevice: false,
    });
  };

  getBranchName = branchId => {
    if (branchId) {
      const branch = this.state.branchList.filter(
        branch => branch.branch_id === branchId
      );
      return branch[0].branch_name + ' - ';
    }
  };

  onChangeSelectedFloorAction = selectedFloor => {
    const {
      selectedFloor: prevFloor,
      deviceMarkerPositionList: prevMarkerList,
    } = this.state;
    const { floor_id: prevFloorId } = prevFloor;
    this.updateMarkerPosition({
      floorId: prevFloorId,
      deviceMarkerPositionList: prevMarkerList,
    });
    this.setState({ selectedFloor, isFloorMapLoading: true }, () => {
      this.getFloorMapUrl(selectedFloor.floor_id);
      this.getDeviceList({
        selectedFloor: selectedFloor,
        selectedTenantId: this.state.tenant,
      });
    });
  };

  addDeviceToFloorMap = (device, floorId) => {
    if (!device.hasPosition) {
      device.hasPosition = true;
      const positionData = {
        top: -2,
        left: 50,
        isDelete: false,
        device,
        isInit: true,
      };
      const hasPositionData = this.state.deviceMarkerPositionList.filter(
        item => item.device.device_identifer === device.device_identifer
      );

      if (hasPositionData.length > 0) {
        hasPositionData[0].isDelete = false;
        this.setState({});
      } else {
        this.setState({
          deviceMarkerPositionList: [
            ...this.state.deviceMarkerPositionList,
            positionData,
          ],
        });
      }

      if (!floorId) {
        return;
      }

      const deviceState = this.deviceStateMap[floorId];
      const { initialDeviceMarkerPositionList } = deviceState;

      initialDeviceMarkerPositionList.push({
        device_identifer: positionData.device.device_identifer,
        position: positionData.device,
      });

      this.deviceStateMap[floorId] = {
        ...this.deviceStateMap[floorId],
        initialDeviceMarkerPositionList,
      };
    }
  };

  deleteMarkerButtonOnClicked = async (e, { device }) => {
    const { device_name } = device;
    confirmDialog({
      message: () => (
        <div className="delete-message-container" style={{ width: '100%' }}>
          <h5>このマーカーを地図から削除しますか？</h5>
          <div
            className="mt-4 flex align-items-center"
            style={{
              paddingBottom: '0.25rem',
            }}
          >
            <div style={{ width: '70px' }}>{`${COMMON_TEXT.NAME}`}</div>
            <div>
              <b>{`${device_name}`}</b>
            </div>
          </div>
        </div>
      ),
      header: COMMON_TEXT.DELETE_CONFIRMATION_HEADER,
      icon: '',
      accept: () => this.removeMarkFromMap({ device }),
      reject: () => {},
      acceptClassName: 'p-button-danger',
      rejectClassName: 'p-button-secondary p-button-text',
      appendTo: 'self',
    });
  };

  meetingRoomButtonOnClicked = (e, { device }) => {
    this.setState({
      isMeetingRoomDialogVisible: true,
      selectedMeetingRoomDevice: { ...device },
      currentMeetingRoomDeviceValue: device?.position?.room_name || '',
      currentShorteningMethodValue: device?.position?.shortening_method || '',
      currentShorteningThreshold1:
        device?.position?.shortening_threshold_1 || null,
      currentShorteningThreshold2:
        device?.position?.shortening_threshold_2 || null,
      currentCancelMethodValue: device?.position?.cancel_method || '',
      currentCancelThreshold1: device?.position?.cancel_threshold_1 || null,
      currentCancelThreshold2: device?.position?.cancel_threshold_2 || null,
      showShorteningThreshold1: this.isShowShorteningThreshold1(
        device?.position?.shortening_method
      ),
      showShorteningThreshold2: this.isShowShorteningThreshold2(
        device?.position?.shortening_method
      ),
      showCancelThreshold1: this.isShowCancelThreshold1(
        device?.position?.cancel_method
      ),
      showCancelThreshold2: this.isShowCancelThreshold2(
        device?.position?.cancel_method
      ),
      cancelThreshold1Title: this.getCancelThreshold1Title(
        device?.position?.cancel_method
      ),
      shorteningThreshold1Title: this.getShorteningThreshold1Title(
        device?.position?.shortening_method
      ),
    });
  };

  removeMarkFromMap = ({ device }) => {
    device.hasPosition = false;
    const foundDevices = this.state.deviceMarkerPositionList.filter(
      item => item.device.device_identifer === device.device_identifer
    );
    if (foundDevices.length > 0) {
      foundDevices[0].isDelete = true;
    }
    this.setState({});
  };

  retrieveSavedDeviceStateByFloorId = ({ floorId }) => {
    return this.deviceStateMap[floorId] || null;
  };

  updateMarkerPosition = ({ floorId, deviceMarkerPositionList }) => {
    floorId = +floorId;
    const markerList = cloneDeep(deviceMarkerPositionList);
    const deviceState = this.deviceStateMap[floorId];
    const { initialDeviceMarkerPositionList } = deviceState;
    markerList.forEach(item => {
      const { device } = item;
      const { position, device_identifer } = device;
      const foundMarkers = initialDeviceMarkerPositionList.filter(
        item => item.device_identifer === device_identifer
      );
      if (foundMarkers && foundMarkers.length > 0) {
        const { position: initialPosition } = foundMarkers[0];
        const { position_x, position_y } = position;
        const isSamePosition =
          position_x === initialPosition.position_x &&
          position_y === initialPosition.position_y;
        if (!isSamePosition) {
          const { top, left } = transformPositionToMarkerCoordinate({
            x: position_x,
            y: 1 - position_y,
          });
          item.top = top;
          item.left = left;
        }
      }
    });
    this.deviceStateMap[floorId] = {
      ...this.deviceStateMap[floorId],
      deviceMarkerPositionList: markerList,
    };
  };

  updateButtonOnClicked = () => {
    const { selectedFloor, deviceMarkerPositionList, floorList } = this.state;
    this.updateMarkerPosition({
      floorId: selectedFloor.floor_id,
      deviceMarkerPositionList,
    });
    const updatePayload = {
      values: [],
    };
    for (const floorId in this.deviceStateMap) {
      const deviceState = this.deviceStateMap[+floorId];
      const {
        deviceMarkerPositionList: devicePositionList,
        initialDeviceMarkerPositionList,
      } = deviceState;
      let deletedItemsPayload = [];
      let updatedItemsPayload = [];

      const deletedItems = [];
      const notDeletedItems = [];
      // Extract Delete Data
      devicePositionList.forEach(item => {
        if (item.isDelete && !item.isInit) {
          deletedItems.push(item);
        } else if (item.isDelete === false) {
          notDeletedItems.push(item);
        }
      });
      for (const deletedItem of deletedItems) {
        const initialItems = initialDeviceMarkerPositionList.filter(
          item => item.device_identifer === deletedItem.device.device_identifer
        );
        if (initialItems.length > 0) {
          deletedItemsPayload.push({
            device_identifer: deletedItem.device.device_identifer,
            device_name: deletedItem.device.device_name,
            position_x: null,
            status: UPDATE_DEVICE_POSITION_STATUS.DELETED,
            shortening_method: deletedItem.device.shortening_method,
            shortening_threshold_1: deletedItem.device.shortening_threshold_1,
            shortening_threshold_2: deletedItem.device.shortening_threshold_2,
            cancel_method: deletedItem.device.cancel_method,
            cancel_threshold_1: deletedItem.device.cancel_threshold_1,
            cancel_threshold_2: deletedItem.device.cancel_threshold_2,
          });
        }
      }

      // Extract Add or Update Data
      notDeletedItems.forEach(finalItem => {
        const { device } = finalItem;
        const { device_identifer, position, device_name } = device ?? {};
        const {
          room_name,
          shortening_method,
          shortening_threshold_1,
          shortening_threshold_2,
          cancel_method,
          cancel_threshold_1,
          cancel_threshold_2,
        } = position ?? {};
        const initialPos = initialDeviceMarkerPositionList.filter(
          item => item.device_identifer === device_identifer
        );

        let newObj = {
          device_identifer: device_identifer,
          device_name: device_name,
          position_x: position.position_x,
          position_y: 1 - position.position_y,
          room_name: room_name,
          shortening_method: shortening_method,
          shortening_threshold_1: shortening_threshold_1,
          shortening_threshold_2: shortening_threshold_2,
          cancel_method: cancel_method,
          cancel_threshold_1: cancel_threshold_1,
          cancel_threshold_2: cancel_threshold_2,
        };

        if (initialPos.length > 0) {
          const isSameData = isEqual(initialPos[0].position, position);
          if (!isSameData) {
            newObj.status = UPDATE_DEVICE_POSITION_STATUS.MODIFIED;
            updatedItemsPayload.push(newObj);
          }
        } else {
          newObj.status = UPDATE_DEVICE_POSITION_STATUS.NEW;
          updatedItemsPayload.push(newObj);
        }
      });

      if (deletedItemsPayload.length > 0 || updatedItemsPayload.length > 0) {
        const floorName = floorList.find(floor => +floor.floor_id === +floorId);
        updatePayload.values.push({
          floor_id: +floorId,
          floor_name: floorName ? floorName.floor_name : '',
          device_positions: [...updatedItemsPayload, ...deletedItemsPayload],
        });
      }
    }
    if (updatePayload.values.length > 0) {
      this.setState({
        isUpdateDialogVisible: true,
        updateDataPayload: updatePayload,
      });
    } else {
      this.toastRef.current.show({
        severity: 'info',
        summary: '情報',
        detail: COMMON_TEXT.NO_CHANGE_TO_BE_SAVED,
        life: 3000,
        closable: true,
      });
    }
  };

  sendRequestUpdatePosition = ({ updateDataPayload }) => {
    if (updateDataPayload.values.length > 0) {
      const cloneUpdateDataPayload = cloneDeep(updateDataPayload);
      const { values } = cloneUpdateDataPayload;
      for (const value of values) {
        delete value.floor_name;
        const { device_positions } = value;
        for (const devicePosition of device_positions) {
          delete devicePosition.device_name;
          delete devicePosition.status;
        }
      }
      this.updateData({ updatePayLoad: cloneUpdateDataPayload });
    }
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    const { isSuccess, executionId } = await updateDeviceLocationInfo({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  renderMap = props => {
    const { device } = props;
    const randomTooltipString = generateRandomString(10);
    if (device) {
      const { device_name } = device;
      return (
        <>
          <Tooltip
            target={`.map-marker-id-${randomTooltipString}`}
            mouseTrack
            mouseTrackLeft={10}
          />
          <DeviceMarker
            deviceinfo={device}
            className={[
              `pi pi-map-marker map-marker-style map-marker-id-${randomTooltipString}`,
            ]}
            data-pr-tooltip={device_name}
            data-pr-classname="child-div-tooltip"
          />
        </>
      );
    }
    return <></>;
  };

  renderMapMarker = props => {
    const { isDelete, device } = props;
    if (isDelete) {
      return <></>;
    }
    if (device) {
      return this.renderMap(props);
    }
    return <></>;
  };

  renderDeviceList = () => {
    const {
      selectedDeviceCategory,
      merakiDevicesList,
      verkadaDevicesList,
      butlrDevicesList,
      webexDevicesList,
      arubaDevicesList,
      tenantInfo,
      selectedFloor,
    } = this.state;
    let deviceList = [];
    switch (selectedDeviceCategory) {
      case DEVICE_CATEGORY.MERAKI:
        deviceList = merakiDevicesList;
        break;
      case DEVICE_CATEGORY.VERKADA:
        deviceList = verkadaDevicesList;
        break;
      case DEVICE_CATEGORY.BUTLR:
        deviceList = butlrDevicesList;
        break;
      case DEVICE_CATEGORY.WEBEX:
        deviceList = webexDevicesList;
        break;
      case DEVICE_CATEGORY.ARUBA:
        deviceList = arubaDevicesList;
        break;
      default:
        break;
    }

    const isAllowInputRoomName =
      !isEmpty(tenantInfo?.schedule_tool) &&
      ALLOW_INPUT_ROOM_NAME_TOOL.includes(tenantInfo?.schedule_tool);
    return (
      <>
        {deviceList.length > 0 ? (
          <>
            {deviceList.map((device, index) => {
              const { hasPosition, canHaveMeetingRoom } = device;
              const canInputMeetingRoom =
                hasPosition && canHaveMeetingRoom && isAllowInputRoomName;
              return (
                <div className="device-item" key={index}>
                  <div className="device-label">
                    <Button
                      className="marker-button"
                      severity="info"
                      onClick={() =>
                        this.addDeviceToFloorMap(
                          device,
                          selectedFloor?.floor_id
                        )
                      }
                      text
                      icon={() => (
                        <DeviceMarker
                          deviceinfo={device}
                          size={24}
                          disabled={!device.hasPosition}
                        />
                      )}
                    ></Button>
                    <Button
                      className={`device-name-button`}
                      onClick={e => {
                        if (canInputMeetingRoom) {
                          this.meetingRoomButtonOnClicked(e, { device });
                        }
                      }}
                      text
                      severity="secondary"
                      label={device.device_name}
                    ></Button>
                  </div>
                  <div className="meeting-room-container">
                    {canInputMeetingRoom && (
                      <Button
                        className={`meeting-room-button ${
                          device?.position?.room_name && 'has-roomname'
                        }`}
                        onClick={e =>
                          this.meetingRoomButtonOnClicked(e, { device })
                        }
                        text
                        icon="pi pi-users"
                        severity="secondary"
                        tooltip={device?.position?.room_name}
                        tooltipOptions={{
                          position: 'left',
                        }}
                      ></Button>
                    )}
                  </div>
                  <div className="trash-container">
                    {device.hasPosition && (
                      <Button
                        className="trash-button"
                        onClick={e =>
                          this.deleteMarkerButtonOnClicked(e, { device })
                        }
                        text
                        icon="pi pi-trash"
                        severity="danger"
                      ></Button>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="mt-2 mb-2">{COMMON_TEXT.NO_DEVICE}</div>
        )}
      </>
    );
  };

  renderSettingContent() {
    const {
      isLoading,
      isUpdating,
      floorList,
      selectedFloor,
      selectedDeviceCategory,
      floorMapUrl,
      floorMapError,
      isFloorMapLoading,
      deviceMarkerPositionList,
      isLoadingDevice,
    } = this.state;
    let markerList = [...deviceMarkerPositionList];
    markerList = markerList.filter(
      item => item.device.deviceCategory === selectedDeviceCategory
    );

    return (
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="setting-content">
            <div className="panel">
              <div className="floor-list-container">
                <div className="title-text">{`${COMMON_TEXT.BRANCH} - ${COMMON_TEXT.FLOOR}`}</div>
                <div className="floor-list">
                  {floorList.length > 0 &&
                    floorList.map((floor, index) => (
                      <div
                        key={`floor-item-index-${index}`}
                        className={`floor-item ${
                          selectedFloor?.floor_id === floor.floor_id &&
                          'checked'
                        }`}
                      >
                        <RadioButton
                          inputId={floor.floor_id}
                          name="floor"
                          value={floor}
                          checked={selectedFloor?.floor_id === floor.floor_id}
                          onChange={e =>
                            this.onChangeSelectedFloorAction(e.value)
                          }
                        ></RadioButton>
                        <label htmlFor={floor.floor_id} className="ml-2">
                          {this.getBranchName(floor.branch_id)}
                          {floor.floor_name}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
              <div className="floor-map-container">
                <div className="title-text">フロアマップ</div>
                <div className="floor-map">
                  {isFloorMapLoading || isLoadingDevice ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <ImageMarker
                        bufferLeft={0}
                        bufferTop={0}
                        src={floorMapUrl}
                        markerComponent={this.renderMapMarker}
                        markers={floorMapError ? [] : markerList || []}
                        isDisableDragMarker={false}
                      />
                      <>
                        {floorMapError && (
                          <Message
                            severity="error"
                            text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
                          />
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>
              <div className="device-list-container">
                <div className="title-text">{COMMON_TEXT.DEVICE}</div>
                <div className="category-dropdown-container">
                  <CustomDropdown
                    className="device-category-dropdown"
                    value={selectedDeviceCategory}
                    options={this.deviceCategoryOptions}
                    onChange={e =>
                      this.setState({ selectedDeviceCategory: e.value })
                    }
                  ></CustomDropdown>
                </div>
                <div className="device-list">
                  {isLoadingDevice ? (
                    <LoadingSpinner />
                  ) : (
                    <>{this.renderDeviceList()}</>
                  )}
                </div>
              </div>
            </div>
            <div className="button-container">
              <Button
                type="submit"
                severity="info"
                label="保存"
                loading={isUpdating}
                className="submit-button has-shadow"
                onClick={this.updateButtonOnClicked}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  renderUpdatePayloadTable = () => {
    const { updateDataPayload } = this.state;
    let payloadValues = cloneDeep(updateDataPayload.values);
    const tableValues = [];
    payloadValues.forEach(item => {
      let floorName = item.floor_name;
      item.device_positions.forEach(device => {
        tableValues.push({
          floor_name: floorName,
          device_name: device.device_name,
          status: device.status,
        });
      });
    });

    const statusBodyTemplate = rowData => {
      return (
        <Tag
          value={getTextStatus(rowData.status)}
          severity={getStatus(rowData.status)}
        />
      );
    };

    const getTextStatus = status => {
      switch (status) {
        case UPDATE_DEVICE_POSITION_STATUS.DELETED:
          return COMMON_TEXT.STATUS_DELETED;

        case UPDATE_DEVICE_POSITION_STATUS.MODIFIED:
          return COMMON_TEXT.STATUS_MODIFIED;

        case UPDATE_DEVICE_POSITION_STATUS.NEW:
          return COMMON_TEXT.STATUS_NEW;

        default:
          return '';
      }
    };

    const getStatus = status => {
      switch (status) {
        case UPDATE_DEVICE_POSITION_STATUS.DELETED:
          return 'danger';

        case UPDATE_DEVICE_POSITION_STATUS.MODIFIED:
          return 'warning';

        case UPDATE_DEVICE_POSITION_STATUS.NEW:
          return 'info';

        default:
          return 'info';
      }
    };

    return (
      <div className="payload-info-container">
        <DataTable
          value={tableValues}
          rowGroupMode="rowspan"
          groupRowsBy="floor_name"
          sortOrder={0}
          scrollable
          scrollHeight="400px"
          header={null}
          tableStyle={{ width: '100%' }}
          size="small"
        >
          <Column
            field="floor_name"
            header={COMMON_TEXT.FLOOR}
            style={{}}
          ></Column>
          <Column
            field="device_name"
            header={COMMON_TEXT.DEVICE}
            style={{}}
          ></Column>
          <Column
            field="status"
            header={COMMON_TEXT.STATUS}
            body={statusBodyTemplate}
            style={{}}
          ></Column>
        </DataTable>
      </div>
    );
  };

  getShorteningThreshold1Title = shorteningMethod => {
    let thresholdTitle1 = '';

    if (shorteningMethod === SHORTENING_METHOD.SILENCE) {
      thresholdTitle1 = COMMON_TEXT.DEVICE_LOCATION_SHORTENING_THRESHOLD_1;
    }

    if (
      shorteningMethod === SHORTENING_METHOD.REPLY_SHORTENING ||
      shorteningMethod === SHORTENING_METHOD.REPLY_KEEP
    ) {
      thresholdTitle1 =
        COMMON_TEXT.DEVICE_LOCATION_SHORTENING_THRESHOLD_1_REPLY;
    }

    return thresholdTitle1;
  };

  isShowShorteningThreshold1 = shorteningMethod =>
    !isNull(shorteningMethod) &&
    !isEmpty(shorteningMethod) &&
    shorteningMethod !== SHORTENING_METHOD.DISABLED;

  isShowShorteningThreshold2 = shorteningMethod =>
    shorteningMethod === SHORTENING_METHOD.REPLY_KEEP;

  renderMeetingRoomShorteningMethod = () => {
    const {
      currentShorteningMethodValue,
      currentShorteningThreshold1,
      currentShorteningThreshold2,
      shorteningThreshold1Title,
      showShorteningThreshold1,
      showShorteningThreshold2,
    } = this.state;

    const shorteningMethodOnChanged = e => {
      const selectedValue = e.target.value;

      let threshold1 = currentShorteningThreshold1;
      let threshold2 = currentShorteningThreshold2;

      if (selectedValue === SHORTENING_METHOD.DISABLED) {
        threshold1 = null;
      }

      if (
        selectedValue === SHORTENING_METHOD.DISABLED ||
        selectedValue === SHORTENING_METHOD.SILENCE ||
        selectedValue === SHORTENING_METHOD.REPLY_SHORTENING
      ) {
        threshold2 = null;
      }

      this.setState({
        currentShorteningMethodValue: selectedValue,
        shorteningThreshold1Title:
          this.getShorteningThreshold1Title(selectedValue),
        showShorteningThreshold1:
          this.isShowShorteningThreshold1(selectedValue),
        showShorteningThreshold2:
          this.isShowShorteningThreshold2(selectedValue),
        currentShorteningThreshold1: threshold1,
        currentShorteningThreshold2: threshold2,
      });
    };

    const shorteningThreshold1OnChanged = e => {
      this.setState({ currentShorteningThreshold1: e.target.value });
    };

    const shorteningThreshold2OnChanged = e => {
      this.setState({ currentShorteningThreshold2: e.target.value });
    };

    return (
      <>
        <h6 className="title-text">
          {COMMON_TEXT.DEVICE_LOCATION_SHORTENING_METHOD}
        </h6>
        <div className="w-full mt-2">
          <Dropdown
            className="shortening-method-dropdown"
            options={this.deviceShorteningMethodOptions}
            value={currentShorteningMethodValue}
            onChange={e => shorteningMethodOnChanged(e)}
          ></Dropdown>
        </div>
        <div className="w-full mt-2 flex">
          {showShorteningThreshold1 && (
            <div className="col-6 pl-0">
              <div>{shorteningThreshold1Title}</div>
              <Dropdown
                className="shortening-threshold-dropdown mt-2"
                options={this.timeOptions}
                value={currentShorteningThreshold1}
                onChange={e => shorteningThreshold1OnChanged(e)}
              ></Dropdown>
            </div>
          )}
          {showShorteningThreshold2 && (
            <div className="col-6 pr-0">
              <div>{COMMON_TEXT.DEVICE_LOCATION_SHORTENING_THRESHOLD_2}</div>
              <Dropdown
                className="shortening-threshold-dropdown mt-2"
                options={this.timeOptions}
                value={currentShorteningThreshold2}
                onChange={e => shorteningThreshold2OnChanged(e)}
              ></Dropdown>
            </div>
          )}
        </div>
      </>
    );
  };

  getCancelThreshold1Title = cancelMethod => {
    let thresholdTitle1 = '';
    if (cancelMethod === CANCEL_METHOD.SILENCE) {
      thresholdTitle1 = COMMON_TEXT.DEVICE_LOCATION_CANCEL_THRESHOLD_1;
    }

    if (
      cancelMethod === CANCEL_METHOD.REPLY_CANCEL ||
      cancelMethod === CANCEL_METHOD.REPLY_KEEP
    ) {
      thresholdTitle1 = COMMON_TEXT.DEVICE_LOCATION_CANCEL_THRESHOLD_1_REPLY;
    }
    return thresholdTitle1;
  };

  isShowCancelThreshold1 = cancelMethod =>
    !isNull(cancelMethod) &&
    !isEmpty(cancelMethod) &&
    cancelMethod !== CANCEL_METHOD.DISABLED;

  isShowCancelThreshold2 = cancelMethod =>
    cancelMethod === CANCEL_METHOD.REPLY_KEEP;

  renderMeetingRoomCancelMethod = () => {
    const {
      currentCancelMethodValue,
      currentCancelThreshold1,
      currentCancelThreshold2,
      cancelThreshold1Title,
      showCancelThreshold1,
      showCancelThreshold2,
    } = this.state;

    const cancelMethodOnChanged = e => {
      const selectedValue = e.target.value;

      let threshold1 = currentCancelThreshold1;
      let threshold2 = currentCancelThreshold2;

      if (selectedValue === CANCEL_METHOD.DISABLED) {
        threshold1 = null;
      }

      if (
        selectedValue === CANCEL_METHOD.DISABLED ||
        selectedValue === CANCEL_METHOD.SILENCE ||
        selectedValue === CANCEL_METHOD.REPLY_CANCEL
      ) {
        threshold2 = null;
      }

      this.setState({
        currentCancelMethodValue: selectedValue,
        cancelThreshold1Title: this.getCancelThreshold1Title(selectedValue),
        showCancelThreshold1: this.isShowCancelThreshold1(selectedValue),
        showCancelThreshold2: this.isShowCancelThreshold2(selectedValue),
        currentCancelThreshold1: threshold1,
        currentCancelThreshold2: threshold2,
      });
    };

    const cancelThreshold1OnChanged = e => {
      this.setState({ currentCancelThreshold1: e.target.value });
    };

    const cancelThreshold2OnChanged = e => {
      this.setState({ currentCancelThreshold2: e.target.value });
    };

    return (
      <>
        <h6 className="title-text">
          {COMMON_TEXT.DEVICE_LOCATION_CANCEL_METHOD}
        </h6>
        <div className="w-full mt-2">
          <Dropdown
            className="cancel-method-dropdown"
            options={this.deviceCancelMethodOptions}
            value={currentCancelMethodValue}
            onChange={e => cancelMethodOnChanged(e)}
          ></Dropdown>
        </div>
        <div className="w-full mt-2 flex">
          {showCancelThreshold1 && (
            <div className="col-6 pl-0">
              <div>{cancelThreshold1Title}</div>
              <Dropdown
                className="cancel-threshold-dropdown mt-2"
                options={this.timeOptions}
                value={currentCancelThreshold1}
                onChange={e => cancelThreshold1OnChanged(e)}
              ></Dropdown>
            </div>
          )}
          {showCancelThreshold2 && (
            <div className="col-6 pr-0">
              <div>{COMMON_TEXT.DEVICE_LOCATION_CANCEL_THRESHOLD_2}</div>
              <Dropdown
                className="cancel-threshold-dropdown mt-2"
                options={this.timeOptions}
                value={currentCancelThreshold2}
                onChange={e => cancelThreshold2OnChanged(e)}
              ></Dropdown>
            </div>
          )}
        </div>
      </>
    );
  };

  renderMeetingRoomSettingContent = () => {
    const { selectedMeetingRoomDevice, tenantInfo, garoonFacilityOptions } =
      this.state;
    let { currentMeetingRoomDeviceValue } = this.state;
    const { device_name } = selectedMeetingRoomDevice || {};
    const { schedule_tool } = tenantInfo || {};

    const inputTextOnChanged = (e, device) => {
      this.setState({ currentMeetingRoomDeviceValue: e.target.value });
    };

    const dropDownOnChanged = (e, device) => {
      this.setState({ currentMeetingRoomDeviceValue: e.target.value });
    };

    if (
      schedule_tool === TENANT_SCHEDULE_TOOL_TYPE.MICROSOFT ||
      schedule_tool === TENANT_SCHEDULE_TOOL_TYPE.GOOGLE
    ) {
      let title = '';
      if (schedule_tool === TENANT_SCHEDULE_TOOL_TYPE.MICROSOFT) {
        title = 'Microsoft Office';
      } else if (schedule_tool === TENANT_SCHEDULE_TOOL_TYPE.GOOGLE) {
        title = 'Google カレンダー';
      }
      return (
        <>
          <div className="meeting-room-container">
            <h5>{device_name}</h5>
            <h6>{`${title} - 会議室のメールアドレス`}</h6>
            <div className="mt-2">
              <InputText
                className="microsoft-input-email"
                value={currentMeetingRoomDeviceValue}
                onChange={e => inputTextOnChanged(e, selectedMeetingRoomDevice)}
              />
            </div>
            <div>{this.renderMeetingRoomShorteningMethod()}</div>
            <div>{this.renderMeetingRoomCancelMethod()}</div>
          </div>
        </>
      );
    } else if (schedule_tool === TENANT_SCHEDULE_TOOL_TYPE.GAROON) {
      return (
        <>
          <div className="meeting-room-container">
            <h5>{device_name}</h5>
            <h6>{`Garoon - 施設を選択`}</h6>
            <div className="mt-2">
              <Dropdown
                className="garoon-facility-dropdown"
                value={currentMeetingRoomDeviceValue}
                options={garoonFacilityOptions}
                onChange={e => dropDownOnChanged(e, selectedMeetingRoomDevice)}
              ></Dropdown>
            </div>
            <div>{this.renderMeetingRoomShorteningMethod()}</div>
            <div>{this.renderMeetingRoomCancelMethod()}</div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="meeting-room-container">
          <h4>{device_name}</h4>
          <h5>{`${COMMON_TEXT.SCHEDULE_TOOL}: N/A`}</h5>
        </div>
      </>
    );
  };

  render() {
    const {
      isUpdateDialogVisible,
      updateDataPayload,
      isUpdating,
      isMeetingRoomDialogVisible,
    } = this.state;
    const updateDataDialogFooter = () => {
      return (
        <React.Fragment>
          <Button
            label={COMMON_TEXT.CANCEL}
            icon="pi pi-times"
            text
            disabled={isUpdating}
            severity="secondary"
            onClick={() => this.setState({ isUpdateDialogVisible: false })}
          />
          <Button
            label={COMMON_TEXT.SAVE}
            severity="info"
            className="submit-button has-shadow"
            loading={isUpdating}
            icon="pi pi-check"
            onClick={e => this.sendRequestUpdatePosition({ updateDataPayload })}
          />
        </React.Fragment>
      );
    };

    const meetingRoomDialogFooter = () => {
      const resetSelectedMeetingRoomDevice = () => {
        this.setState({
          isMeetingRoomDialogVisible: false,
          currentMeetingRoomDeviceValue: '',
          currentCancelMethodValue: '',
          currentCancelThreshold1: null,
          currentCancelThreshold2: null,
          showCancelThreshold1: false,
          showCancelThreshold2: false,
          currentShorteningMethodValue: '',
          currentShorteningThreshold1: null,
          currentShorteningThreshold2: null,
          showShorteningThreshold1: false,
          showShorteningThreshold2: false,
        });
      };

      const updateSelectedMeetingRoomDevice = () => {
        const {
          selectedMeetingRoomDevice,
          merakiDevicesList,
          butlrDevicesList,
          arubaDevicesList,
          deviceMarkerPositionList,
          currentMeetingRoomDeviceValue,
          currentCancelMethodValue,
          currentCancelThreshold1,
          currentCancelThreshold2,
          currentShorteningMethodValue,
          currentShorteningThreshold1,
          currentShorteningThreshold2,
        } = this.state;
        const { deviceCategory, device_identifer } = selectedMeetingRoomDevice;

        const updateDeviceList = (deviceList, identifierKey) => {
          const index = deviceList.findIndex(
            item => item[identifierKey] === device_identifer
          );

          if (index > -1) {
            Object.assign(deviceList[index].position, {
              room_name: currentMeetingRoomDeviceValue,
              shortening_method: currentShorteningMethodValue,
              shortening_threshold_1: currentShorteningThreshold1,
              shortening_threshold_2: currentShorteningThreshold2,
              cancel_method: currentCancelMethodValue,
              cancel_threshold_1: currentCancelThreshold1,
              cancel_threshold_2: currentCancelThreshold2,
            });
          }
        };

        // Update room_name in deviceMarkerPositionList
        const findPosIndex = deviceMarkerPositionList.findIndex(
          item => item.device.device_identifer === device_identifer
        );
        if (findPosIndex > -1) {
          Object.assign(
            deviceMarkerPositionList[findPosIndex].device.position,
            {
              room_name: currentMeetingRoomDeviceValue,
              shortening_method: currentShorteningMethodValue,
              shortening_threshold_1: currentShorteningThreshold1,
              shortening_threshold_2: currentShorteningThreshold2,
              cancel_method: currentCancelMethodValue,
              cancel_threshold_1: currentCancelThreshold1,
              cancel_threshold_2: currentCancelThreshold2,
            }
          );
        }

        // Update info in device list
        if (deviceCategory === DEVICE_CATEGORY.MERAKI) {
          updateDeviceList(merakiDevicesList, 'device_identifer');
        } else if (deviceCategory === DEVICE_CATEGORY.BUTLR) {
          updateDeviceList(butlrDevicesList, 'device_identifer');
        } else if (deviceCategory === DEVICE_CATEGORY.ARUBA) {
          updateDeviceList(arubaDevicesList, 'device_identifer');
        }

        this.setState({
          deviceMarkerPositionList,
          merakiDevicesList,
          butlrDevicesList,
          arubaDevicesList,
          isMeetingRoomDialogVisible: false,
          currentMeetingRoomDeviceValue: '',
        });
      };
      return (
        <React.Fragment>
          <Button
            label={COMMON_TEXT.CANCEL}
            icon="pi pi-times"
            text
            disabled={isUpdating}
            severity="secondary"
            onClick={e => resetSelectedMeetingRoomDevice()}
          />
          <Button
            label={`保存`}
            severity="info"
            className="submit-button has-shadow"
            loading={isUpdating}
            icon="pi pi-check"
            onClick={e => updateSelectedMeetingRoomDevice()}
          />
        </React.Fragment>
      );
    };

    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <Dialog
          visible={isUpdateDialogVisible}
          style={{ width: '44rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={COMMON_TEXT.DEVICE_LOCATION_CHANGE_HEADER}
          modal
          className="p-fluid"
          footer={updateDataDialogFooter}
          onHide={() => this.setState({ isUpdateDialogVisible: false })}
          appendTo={'self'}
        >
          <div className="confirmation-content">
            {this.renderUpdatePayloadTable()}
          </div>
        </Dialog>
        <Dialog
          visible={isMeetingRoomDialogVisible}
          style={{ width: '100%', maxWidth: '440px' }}
          header={`会議室の設定`}
          modal
          className="p-fluid"
          footer={meetingRoomDialogFooter}
          onHide={() => this.setState({ isMeetingRoomDialogVisible: false })}
          appendTo={'self'}
        >
          <div className="meeting-room-setting-content">
            {this.renderMeetingRoomSettingContent()}
          </div>
        </Dialog>
        <div className="config-container custom-config-container device-location-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">
                {COMMON_TEXT.DEVICE_LOCATION_SETTING}
              </div>
            </div>
            <div className="grid-nogutter align-items-center justify-content-end grid">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">{this.renderSettingContent()}</div>
        </div>
      </>
    );
  }
}

DeviceLocationSetting.propTypes = {};

export default DeviceLocationSetting;
