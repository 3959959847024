import { COMMON_TEXT } from '@/helpers/common-text';
import {
  EXECUTION_ID_TIMEOUT,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
} from '@/helpers/constants';
import AuthToken from '@/services/auth-token';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import ConfigUpdateStatus from '@/components/organization/Configuration/ConfigUpdateStatus';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { Toast } from 'primereact/toast';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class SignageComponentNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notice: '',
      errorNotice: '',
      isUpdating: false,
    };
    this.authToken = new AuthToken();
    this.toastRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      const input = document.querySelector('#notice-input-id');
      if (input) {
        this.checkInput(input);
      }
    });

    const { notice, isLoading } = this.props;
    this.setState({
      notice,
      isLoading,
    });

    const { sessionStore } = this.props;
    const sessionExecutionList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (sessionExecutionList && sessionExecutionList.length > 0) {
      this.setState({ isActionUpdating: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { notice, isLoading } = this.props;
    const { notice: prevNotice, isLoading: prevIsLoading } = prevProps;

    const isDataChanged = notice !== prevNotice;
    const isLoadingChanged = isLoading !== prevIsLoading;

    if (isDataChanged || isLoadingChanged) {
      this.setState({
        notice,
        isLoading,
      });
    }
  }

  onChange = value => {
    const escapedValue = value.replace(/\n/g, '\\n');
    this.setState({ notice: escapedValue }, () => {
      const input = document.querySelector('#notice-input-id');
      if (input) {
        this.checkInput(input);
      }
    });
  };

  updateButtonOnClicked = () => {
    const { notice } = this.state;
    const escapedValue = notice.replace(/\n/g, '\\n');

    const updatePayload = {
      value: {
        notice: escapedValue,
      },
    };
    if (updatePayload.value) {
      this.updateData({ updatePayLoad: updatePayload });
    }
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    // eslint-disable-next-line
    const { isSuccess, executionId } = await this.updateSetComponents({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false, isActionUpdating: true });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  updateSetComponents = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.SIGNAGE_NOTICES,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('update signage notice error');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('update signage notice error', error);
    }
    return { isSuccess: false };
  };

  calculateLines(content, inputWidth, context) {
    let currentLineWidth = 0;
    let lines = 1;

    for (let i = 0; i < content.length; i++) {
      const charWidth = context.measureText(content[i]).width;
      if (currentLineWidth + charWidth > inputWidth) {
        lines++;
        currentLineWidth = charWidth;
      } else {
        currentLineWidth += charWidth;
      }
    }

    return lines;
  }

  checkInput(inputElement) {
    const inputWidth = inputElement.offsetWidth - 28; // 28px is the number of left and right padding: 24px, left and right margin: 4px
    const maxLines = 3;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = getComputedStyle(inputElement).font;

    const content = inputElement.value;

    const lines = this.calculateLines(content, inputWidth, context);

    const lineBreaks = (content.match(/\n/g) || []).length;

    const totalLines = lines + lineBreaks;

    if (totalLines > maxLines) {
      this.setState({ errorNotice: COMMON_TEXT.INPUT_NOTICE_ERROR_MESSAGE });
    } else {
      this.setState({ errorNotice: '' });
    }
  }

  updateConfigStatus = ({ isActionUpdating }) => {
    this.props.updateNotice({
      isUpdate: true,
    });
    this.setState({ isActionUpdating });
  };

  render() {
    const { notice, isUpdating, errorNotice, isActionUpdating } = this.state;
    const { isLoading } = this.props;

    const canSubmit = errorNotice.length > 0;
    const noticeStr = notice.replace(/\\n/g, '\n');

    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <div className="signage-components-notice-container main-container">
          {isLoading || isUpdating ? (
            <LoadingSpinner />
          ) : (
            <>
              {isActionUpdating ? (
                <ConfigUpdateStatus
                  updateConfigStatus={this.updateConfigStatus}
                ></ConfigUpdateStatus>
              ) : (
                <>
                  {errorNotice && (
                    <div className="validation-error-message">
                      {errorNotice}
                    </div>
                  )}
                  <div className="input-container">
                    <InputTextarea
                      id="notice-input-id"
                      autoResize
                      value={noticeStr}
                      onChange={e => this.onChange(e.target.value)}
                    />
                  </div>
                  <div className="button-container is-submit-button-container">
                    <Button
                      severity="info"
                      label="保存"
                      className="submit-button has-shadow"
                      loading={isUpdating}
                      disabled={canSubmit}
                      onClick={this.updateButtonOnClicked}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

SignageComponentNotice.propTypes = {
  updateNotice: PropTypes.func,
};
export default SignageComponentNotice;
