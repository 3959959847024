import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  EXECUTION_ID_TIMEOUT,
  FOLDER_NAME,
  BUCKET_NAME as IMAGE_BUCKET_NAME,
  MAX_UPLOAD_IMAGE_FILE_SIZE,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
} from '@/helpers/constants';
import { getConfigurationUrlWithParam } from '@/helpers/utility';
import FpDataStore from '@/services/FpDatastore';
import AuthToken from '@/services/auth-token';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { Field, FieldArray, Form, Formik, getIn } from 'formik';
import { cloneDeep, floor, isEmpty } from 'lodash';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import {
  fetchArubaBuildingListByBranchIdList,
  fetchArubaFloorListByBuildingIdList,
  fetchBranchList,
  fetchFloorListByBranchIdList,
  fetchMerakiFloorListByNetworkIdList,
  fetchMerakiNetworkListByBranchIdList,
} from './query-request';
import { Dropdown } from 'primereact/dropdown';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class FloorSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      dataList: [],
    };
    this.originalDataList = [];
    this.toastRef = React.createRef();
    this.fpDataStore = new FpDataStore();
    this.authToken = new AuthToken();
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button has-shadow"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getNullFloorRelatedId = selectedFloor => {
    return typeof selectedFloor === 'object'
      ? selectedFloor.value
      : selectedFloor;
  };

  findItemChanges({ updatedArray, originalArray }) {
    const result = {
      newItemsMap: {},
      deletedItemsMap: {},
      updatedItemsMap: {},
    };

    // convert originalArray and updatedArray to a map with key is branchId and value is floorList
    const originalMap = {};
    originalArray.forEach(item => {
      originalMap[item.branchId] = item.floorList || [];
    });

    const updatedMap = {};
    updatedArray.forEach(item => {
      updatedMap[item.branchId] = item.floorList || [];
    });

    // Find deleted items
    for (let branchId in originalMap) {
      const originalFloorList = originalMap[branchId];
      const updatedFloorList = updatedMap[branchId];
      // find item that is in orgFloorList but not in floorList
      const deletedFloorList = originalFloorList.filter(
        o => !updatedFloorList.find(f => f.floorId === o.floorId)
      );
      if (deletedFloorList.length > 0) {
        result.deletedItemsMap[branchId] = deletedFloorList;
      }

      // find new item, new item is item in updatedFloorList and have null floorId
      const newFloorList = updatedFloorList.filter(o => !o.floorId);
      if (newFloorList.length > 0) {
        result.newItemsMap[branchId] = newFloorList.map(floor => ({
          ...floor,
          relatedFloorId: this.getNullFloorRelatedId(floor.relatedFloorId),
        }));
      }

      // find changed item, changed item is item that both in updatedFloorList and originalFloorList
      // and have same floorId and have different floorName or floorMap
      const changedFloorList = updatedFloorList.filter(o => {
        const relatedFloorIdValue = this.getNullFloorRelatedId(
          o.relatedFloorId
        );
        const orgItem = originalFloorList.find(
          orgItem => orgItem.floorId === o.floorId
        );
        return (
          orgItem &&
          (orgItem.floorName !== o.floorName ||
            orgItem.floorMap !== o.floorMap ||
            orgItem.floorOrder !== o.floorOrder ||
            orgItem.relatedFloorId !== relatedFloorIdValue ||
            orgItem.scaleX !== o.scaleX ||
            orgItem.scaleY !== o.scaleY)
        );
      });
      if (changedFloorList.length > 0) {
        result.updatedItemsMap[branchId] = changedFloorList.map(floor => ({
          ...floor,
          relatedFloorId: this.getNullFloorRelatedId(floor.relatedFloorId),
        }));
      }
    }
    return result;
  }

  getUpdateDataPayload = ({
    newItemsMap,
    deletedItemsMap,
    updatedItemsMap,
  }) => {
    let payload = {
      values: [],
    };
    const branchIdSet = new Set();
    if (!isEmpty(newItemsMap)) {
      Object.keys(newItemsMap).forEach(branchId => {
        branchIdSet.add(branchId);
      });
    }
    if (!isEmpty(deletedItemsMap)) {
      Object.keys(deletedItemsMap).forEach(branchId => {
        branchIdSet.add(branchId);
      });
    }
    if (!isEmpty(updatedItemsMap)) {
      Object.keys(updatedItemsMap).forEach(branchId => {
        branchIdSet.add(branchId);
      });
    }

    for (const branchId of branchIdSet) {
      const newFloors = newItemsMap?.[branchId] || [];
      const branchObject = {
        branch_id: +branchId,
        floors: [],
      };
      if (newFloors.length > 0) {
        for (const floor of newFloors) {
          branchObject.floors.push({
            floor_id: null,
            name: floor.floorName,
            floor_map: floor.floorMap,
            floor_order: floor.floorOrder,
            related_floor_id: floor.relatedFloorId,
            related_product: floor.relatedProduct,
            scale_x: floor.scaleX,
            scale_y: floor.scaleY,
          });
        }
      }

      const deletedFloors = deletedItemsMap?.[branchId] || [];
      if (deletedFloors.length > 0) {
        for (const floor of deletedFloors) {
          branchObject.floors.push({
            floor_id: floor.floorId,
            name: null,
          });
        }
      }

      const updatedFloors = updatedItemsMap?.[branchId] || [];
      if (updatedFloors.length > 0) {
        for (const floor of updatedFloors) {
          branchObject.floors.push({
            floor_id: floor.floorId,
            name: floor.floorName,
            floor_map: floor.floorMap,
            floor_order: floor.floorOrder,
            related_floor_id: floor.relatedFloorId,
            related_product: floor.relatedProduct,
            scale_x: floor.scaleX,
            scale_y: floor.scaleY,
          });
        }
      }
      payload.values.push(branchObject);
    }
    return payload;
  };

  getBuildingAndNetworkList = async branchIdList => {
    let arubaBuildingList = await fetchArubaBuildingListByBranchIdList({
      branchIdList,
    });
    arubaBuildingList = arubaBuildingList || [];
    const buildingIds = arubaBuildingList.map(item => item.building_id);
    let arubaFloors = await fetchArubaFloorListByBuildingIdList({
      buildingIds,
    });

    let merakiNetworkList = await fetchMerakiNetworkListByBranchIdList({
      branchIdList,
    });
    merakiNetworkList = merakiNetworkList || [];
    const networkIds = merakiNetworkList.map(item => item.network_identifer);
    let merakiFloors = await fetchMerakiFloorListByNetworkIdList({
      networkIds,
    });

    const result = branchIdList.reduce((acc, item) => {
      acc[item] = {
        aruba: arubaFloors
          .filter(floor =>
            arubaBuildingList.some(
              building =>
                building.branch_id === item &&
                building.building_id === floor.building_id
            )
          )
          .map(floor => ({
            value: floor.floor_identifer,
            label: floor.floor_name,
          })),
        meraki: merakiFloors
          .filter(floor =>
            merakiNetworkList.some(
              network =>
                network.branch_id === item &&
                network.network_identifer === floor.network_identifer
            )
          )
          .map(floor => ({
            value: floor.floor_identifer,
            label: floor.floor_name,
          })),
      };
      return acc;
    }, {});

    return result;
  };

  getData = async () => {
    this.setState({ isLoading: true });
    const { tenant } = this.state;
    // fetch data here
    // set time out 1 seconds
    let branchList = await fetchBranchList({ tenantId: tenant });
    branchList = branchList || [];
    const branchIdList = branchList.map(branch => branch.branch_id);
    let floorList = await fetchFloorListByBranchIdList({ branchIdList });
    floorList = floorList || [];

    let additionalInfo = await this.getBuildingAndNetworkList(branchIdList);

    branchList.forEach(branch => {
      branch.floor_list = floorList.filter(
        floor => floor.branch_id === branch.branch_id
      );
      branch.groupedOptions = [
        {
          label: '',
          value: '',
          items: [
            {
              label: 'なし',
              value: '',
            },
          ],
        },
        {
          label: 'Merakiネットワーク',
          value: 'meraki',
          items: cloneDeep(additionalInfo[branch.branch_id].meraki),
        },
        {
          label: 'Arubaサイト',
          value: 'aruba',
          items: cloneDeep(additionalInfo[branch.branch_id].aruba),
        },
      ];
    });

    const transformedBranchList = branchList.map(branch => ({
      branchId: +branch.branch_id,
      branchName: branch.branch_name,
      branchOrder: branch.branch_order,
      groupedOptions: branch.groupedOptions,
      floorList: branch.floor_list.map(floor => ({
        floorId: +floor.floor_id,
        floorName: floor.floor_name,
        branchId: floor.branch_id,
        floorMap: floor.floor_map,
        relatedFloorId: floor.related_floor_id || {
          label: 'なし',
          value: '',
        },
        relatedProduct: floor.related_product,
        floorOrder: floor.floor_order,
        scaleX: floor.scale_x ?? 0,
        scaleY: floor.scale_y ?? 0,
      })),
    }));
    this.originalDataList = cloneDeep(transformedBranchList);
    this.setState({
      dataList: cloneDeep(transformedBranchList),
    });
    this.setState({ isLoading: false });
  };

  updateFloorInfo = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.FLOORS,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    const { isSuccess, executionId } = await this.updateFloorInfo({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  renderSettingContent() {
    const { isLoading, isUpdating, dataList } = this.state;

    const addNewFloor = ({ arrayHelpers, item, branchId }) => {
      arrayHelpers.push({
        floorId: '',
        floorName: '',
        branchId: branchId,
        floorOrder: item.length + 1,
        floorMap: '',
        scaleX: 0,
        scaleY: 0,
      });
    };

    const deleteAccepted = ({ arrayHelpers, index, item }) => {
      // remove for UI
      arrayHelpers.remove(index);
      // remove index
      const selectedFloorList = arrayHelpers.form.values.branches.filter(
        branch => branch.branchId === item.branchId
      )[0].floorList;
      selectedFloorList.splice(index, 1);

      // rearrange floorOrder
      selectedFloorList.map((floor, floorIndex) => {
        floor.floorOrder = floorIndex + 1;
        return '';
      });
    };

    const deleteButtonOnClicked = (e, { arrayHelpers, item, index }) => {
      const { floorId } = item;
      if (floorId) {
        confirmDialog({
          message: COMMON_TEXT.DELETE_CONFIRM_MESSAGE,
          header: COMMON_TEXT.DELETE_CONFIRMATION_HEADER,
          icon: '',
          accept: () => deleteAccepted({ arrayHelpers, index, item }),
          reject: () => {},
          acceptClassName: 'p-button-danger',
          rejectClassName: 'p-button-secondary p-button-text',
          appendTo: 'self',
        });
      } else {
        deleteAccepted({ arrayHelpers, index, item });
      }
    };

    const handleSubmit = (values, { setSubmitting, errors }) => {
      if (!errors) {
        // this.updateData();
        const { deletedItemsMap, newItemsMap, updatedItemsMap } =
          this.findItemChanges({
            updatedArray: values.branches,
            originalArray: this.originalDataList,
          });

        const updatePayLoad = this.getUpdateDataPayload({
          newItemsMap,
          deletedItemsMap,
          updatedItemsMap,
        });
        const { values: payloadValues } = updatePayLoad;
        if (payloadValues.length === 0) {
          return;
        }
        this.updateData({ updatePayLoad });
      } else {
      }
    };

    const isFormFieldInvalid = ({ name, touched, errors }) => {
      const error = getIn(errors, name);
      const touch = getIn(touched, name);
      return touch && error ? 'p-invalid' : '';
    };

    const getFormErrorMessage = ({ name, touched, errors }) => {
      return isFormFieldInvalid({ name, touched, errors }) ? (
        <span className="validation-error-message">{getIn(errors, name)}</span>
      ) : (
        <></>
      );
    };

    const formSchema = Yup.object().shape({
      branches: Yup.array().of(
        Yup.object().shape({
          floorList: Yup.array().of(
            Yup.object().shape({
              floorName: Yup.string().required(COMMON_TEXT.REQUIRED),
            })
          ),
        })
      ),
    });

    const uploadFileToBucket = async ({ file, fileName }) => {
      const BUCKET_NAME = IMAGE_BUCKET_NAME.STATIC;
      const FILE_PATH = `${FOLDER_NAME.FLOOR_MAP}/${fileName}`;
      const FULL_FILE_PATH = `${BUCKET_NAME}/${FILE_PATH}`;
      try {
        let metadata = {
          author: 'TK_FOREPAAS_APP',
        };

        // eslint-disable-next-line
        const result = await this.fpDataStore.uploadObject(
          { filename: FILE_PATH, bucket: BUCKET_NAME, metadata: metadata },
          file,
          progress => {}
        );

        return { success: true, fileUrl: `${FULL_FILE_PATH}` };
      } catch (err) {}
      return { success: false, fileUrl: null };
    };

    const onTemplateUpload = async (event, { item, index, arrayHelpers }) => {
      const files = event.files;
      if (files.length > 1) {
        this.toastRef.current.show({
          severity: 'error',
          summary: COMMON_TEXT.UPLOAD_ERROR_MESSAGE,
          detail: COMMON_TEXT.UPLOAD_MULTIPLE_ERROR_MESSAGE,
        });
        event.options.clear();
      }
      // Iterate over each file and perform a custom upload operation
      for (let file of files) {
        // Simulate a successful file upload with a delay

        // get extension from file name
        const extension = file.name.split('.').pop();

        // generate new file name
        const newFileName = `${uuidv4()}.${extension}`;
        try {
          if (file.size > MAX_UPLOAD_IMAGE_FILE_SIZE) {
            // For example, check if file size is more than 1MB
            throw new Error(COMMON_TEXT.UPLOAD_EXCEED_MESSAGE);
          }
          const { success: isUploadSuccess, fileUrl } =
            await uploadFileToBucket({
              file,
              fileName: newFileName,
            });
          if (!isUploadSuccess) {
            throw new Error(COMMON_TEXT.UPLOAD_FAILED_MESSAGE);
          }
          // set employee image
          item['floorMap'] = fileUrl;
          arrayHelpers.replace(index, item);

          event.options.clear();
          this.toastRef.current.show({
            severity: 'success',
            summary: COMMON_TEXT.UPLOAD_MESSAGE,
            detail: COMMON_TEXT.UPLOAD_MESSAGE_SUCCESS,
          });
        } catch (error) {
          this.toastRef.current.show({
            severity: 'error',
            summary: COMMON_TEXT.UPLOAD_ERROR_MESSAGE,
            detail: error.message,
          });
          event.options.clear();
        }
      }
    };

    const onDragEnd = (result, { floorListHelpers, floors }) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const sourceItem = floors[result.source.index];
      const destinationItem = floors[result.destination.index];
      sourceItem.floorOrder = result.destination.index + 1;
      destinationItem.floorOrder = result.source.index + 1;
      floorListHelpers.replace(result.source.index, destinationItem);
      floorListHelpers.replace(result.destination.index, sourceItem);
    };

    const floorItemContent = ({
      floorInfo,
      floorIndex,
      index, // branch index
      handleChange,
      setFieldValue,
      touched,
      errors,
      snapshot,
      provided,
      branch,
      floorListHelpers,
    }) => {
      const getRealateProduct = (selectedValue, options) => {
        const result = options.find(option =>
          option.items.some(item => item.value === selectedValue)
        );

        if (result) {
          return result.value;
        }

        return '';
      };

      const handleChangeArubaMeraki = e => {
        const selectedValue = e.value;

        setFieldValue(
          `branches[${index}].floorList[${floorIndex}].relatedFloorId`,
          selectedValue
        );

        const product = getRealateProduct(selectedValue, branch.groupedOptions);

        setFieldValue(
          `branches[${index}].floorList[${floorIndex}].relatedProduct`,
          product
        );
      };
      return (
        <>
          <div
            className={`floor-item-container ${
              snapshot.isDragging ? 'is-dragging' : ''
            }`}
            key={floorIndex}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="floor-order-field">{floorInfo.floorOrder}</div>
            <div className="input-text-field-container floor-name-field">
              <InputText
                id={`branches[${index}].floorList[${floorIndex}].floorName`}
                value={floorInfo.floorName}
                onChange={handleChange}
                className={`${isFormFieldInvalid({
                  name: `branches[${index}].floorList[${floorIndex}].floorName`,
                  touched,
                  errors,
                })}`}
              />
              {getFormErrorMessage({
                name: `branches[${index}].floorList[${floorIndex}].floorName`,
                touched,
                errors,
              })}
            </div>
            <div className="input-text-field-container floor-related-field">
              <Field
                type="hidden"
                id={`branches[${index}].floorList[${floorIndex}].relatedProduct`}
                name={`branches[${index}].floorList[${floorIndex}].relatedProduct`}
                value={floorInfo.relatedProduct}
              />
              <Dropdown
                id={`branches[${index}].floorList[${floorIndex}].relatedFloorId`}
                value={floorInfo.relatedFloorId}
                onChange={handleChangeArubaMeraki}
                options={branch.groupedOptions}
                optionGroupLabel="label"
                optionGroupChildren="items"
                className={`${isFormFieldInvalid({
                  name: `branches[${index}].floorList[${floorIndex}].relatedFloorId`,
                  touched,
                  errors,
                })}`}
              ></Dropdown>
              {getFormErrorMessage({
                name: `branches[${index}].floorList[${floorIndex}].relatedFloorId`,
                touched,
                errors,
              })}
            </div>
            <div className="input-text-field-container floor-map-field">
              <div className="empl-image-preview-field">
                {floorInfo.floorMap ? (
                  <Image
                    src={this.fpDataStore.getObjectDownloadUrlWithBucket(
                      floorInfo.floorMap
                    )}
                    preview
                    alt="No Image"
                    className="flex align-items-center justify-content-center"
                    style={{
                      width: '90%',
                      height: '90%',
                    }}
                    width="36"
                    height="auto"
                  />
                ) : (
                  <i className="pi pi-eye-slash"></i>
                )}
              </div>
              <FileUpload
                id={`branches[${index}].floorList[${floorIndex}].floorMap`}
                mode="advanced"
                auto
                name="floorImgUpload[]"
                accept="image/*"
                chooseOptions={{
                  icon: 'pi pi-cloud-upload',
                  iconOnly: true,
                }}
                customUpload
                uploadHandler={e => {
                  onTemplateUpload(e, {
                    item: floorInfo,
                    index: floorIndex,
                    arrayHelpers: floorListHelpers,
                  });
                }}
              />
              {getFormErrorMessage({
                name: `branches[${index}].floorList[${floorIndex}].floorMap`,
                touched,
                errors,
              })}
            </div>
            <div className="input-text-field-container floor-action-field">
              <Button
                type="button"
                icon="pi pi-trash"
                text
                severity="danger"
                className="icon-only-delete-button"
                onClick={e =>
                  deleteButtonOnClicked(e, {
                    arrayHelpers: floorListHelpers,
                    item: floorInfo,
                    index: floorIndex,
                  })
                }
              />
            </div>
          </div>
        </>
      );
    };

    const branchRowContent = ({
      branch,
      index,
      handleChange,
      setFieldValue,
      touched,
      errors,
    }) => {
      return (
        <div className="main-row-container" key={index}>
          <div className="input-text-field-container branch-index-field">
            <div>{branch.branchOrder}</div>
          </div>
          <div className="input-text-field-container branch-name-field">
            <div>{branch.branchName}</div>
          </div>
          <div className="input-text-field-container floorlist-field">
            <FieldArray
              name={`branches[${index}].floorList`}
              render={floorListHelpers => (
                <DragDropContext
                  onDragEnd={e =>
                    onDragEnd(e, {
                      floorListHelpers,
                      floors: branch.floorList,
                    })
                  }
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {branch.floorList
                          .sort((a, b) => a.floorOrder - b.floorOrder)
                          .map((floorInfo, floorIndex) => (
                            <Draggable
                              key={floorInfo.floorOrder}
                              draggableId={`draggable-${floorInfo.floorOrder}`}
                              index={floorIndex}
                            >
                              {(provided, snapshot) =>
                                floorItemContent({
                                  floorInfo,
                                  floorIndex,
                                  index, // branch index
                                  handleChange,
                                  setFieldValue,
                                  touched,
                                  errors,
                                  snapshot,
                                  provided,
                                  branch,
                                  floorListHelpers,
                                })
                              }
                            </Draggable>
                          ))}
                        <div className="floor-item-container add-floor-button-container">
                          <Button
                            severity="secondary"
                            type="button"
                            label="追加"
                            className="add-new-button has-shadow"
                            onClick={() =>
                              addNewFloor({
                                arrayHelpers: floorListHelpers,
                                item: branch.floorList,
                                branchId: branch.branchId,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            />
          </div>
        </div>
      );
    };

    return (
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            initialValues={{ branches: dataList }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleChange, setFieldValue, touched, errors }) => (
              <Form>
                <FieldArray
                  name="branches"
                  render={arrayHelpers => (
                    <>
                      <div className="main-content">
                        <div className="main-row-container is-header">
                          <div className="branch-index-field"></div>
                          <div className="branch-name-field">
                            {COMMON_TEXT.FLOOR_SETTING_HEADER1}
                          </div>
                          <div className="floorlist-field">
                            <div className="floor-order-field">
                              {COMMON_TEXT.FLOOR_SETTING_HEADER2}
                            </div>
                            <div className="floor-name-field">
                              {COMMON_TEXT.FLOOR_SETTING_HEADER3}
                            </div>
                            <div className="floor-related-field">
                              {COMMON_TEXT.FLOOR_SETTING_HEADER4}
                            </div>
                            <div className="floor-map-field">
                              {COMMON_TEXT.FLOOR_SETTING_HEADER5}
                            </div>
                            <div className="floor-action-field"></div>
                          </div>
                        </div>
                        <div>
                          {values.branches
                            .sort((a, b) => a.branchOrder - b.branchOrder)
                            .map((branch, index) =>
                              branchRowContent({
                                branch,
                                index,
                                handleChange,
                                setFieldValue,
                                touched,
                                errors,
                              })
                            )}
                        </div>
                      </div>
                      <div className="main-row-container is-submit-button-container">
                        <Button
                          severity="info"
                          type="submit"
                          label="保存"
                          className="submit-button has-shadow"
                          loading={isUpdating}
                        />
                      </div>
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <div className="config-container custom-config-container floor-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">{COMMON_TEXT.FLOOR_SETTING}</div>
            </div>
            <div className="grid grid-nogutter align-items-center justify-content-end">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">
            <div className="setting-content">{this.renderSettingContent()}</div>
          </div>
        </div>
      </>
    );
  }
}

FloorSetting.propTypes = {};

export default FloorSetting;
