export const DEVICE_TYPE = {
  MR: 'アクセスポイント',
  MS: 'スイッチ',
  MX: 'セキュリティアプライアンス',
  MV: 'カメラ - Meraki',
  MT30: 'センサー - ボタン',
  MT20: 'センサー - ドア',
  Z: 'テレワーカーゲートウェイ',
  MG: 'セルラーゲートウェイ',
  MT: 'センサー - その他',
  CD: 'カメラ - Verkada',
};

export const SHORTENING_METHOD = {
  DISABLED: 'disabled',
  SILENCE: 'silence',
  REPLY_SHORTENING: 'reply_shortening',
  REPLY_KEEP: 'reply_keep',
};

export const CANCEL_METHOD = {
  DISABLED: 'disabled',
  SILENCE: 'silence',
  REPLY_CANCEL: 'reply_cancel',
  REPLY_KEEP: 'reply_keep',
};
