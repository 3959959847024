export const SENSOR_COLOR = {
  GREEN: 'rgba(0, 168, 107, 1.0)',
  YELLOW: 'rgba(255, 225, 53, 0.9)',
  ORANGE: 'rgba(255, 153, 68, 1.0)',
  RED: 'rgba(210, 20, 4, 0.9)',
  LIGHT_BLUE: 'rgba(10, 215, 220, 1)',
  BLUE: 'rgba(25, 74, 255, 1)',
  DEEP_BLUE: 'rgba(0, 39, 138, 1)',
};

export const SENSOR_TABLE_RGBA_COLOR = {
  GREEN: 'rgba(0, 168, 107, 1.0)',
  YELLOW: 'rgba(255, 225, 53, 0.9)',
  ORANGE: 'rgba(255, 153, 68, 1.0)',
  RED: 'rgba(210, 20, 4, 0.9)',
  LIGHT_BLUE: 'rgba(10, 215, 220, 1)',
  BLUE: 'rgba(25, 74, 255, 1)',
  DEEP_BLUE: 'rgba(0, 39, 138, 1)',
};

export const SENSOR_MARKER_RGBA_COLOR = {
  GREEN: 'rgba(0, 168, 107, 0.5)',
  YELLOW: 'rgba(255, 225, 53, 0.7)',
  ORANGE: 'rgba(255, 153, 68, 0.7)',
  RED: 'rgba(210, 20, 4, 0.6)',
  LIGHT_BLUE: 'rgba(10, 215, 220, 0.7)',
  BLUE: 'rgba(25, 74, 255, 0.7)',
  DEEP_BLUE: 'rgba(0, 39, 138, 0.7)',
};

export const TEMPERATURE_RGBA_COLOR = {
  GREEN: 'rgba(0, 168, 107, 1)',
  YELLOW: 'rgba(255, 225, 53, 0.8)',
  ORANGE: 'rgba(255, 153, 68, 1)',
  RED: 'rgba(210, 20, 4, 1)',
  LIGHT_BLUE: 'rgba(10, 215, 220, 1)',
  BLUE: 'rgba(25, 74, 255, 1)',
  DEEP_BLUE: 'rgba(0, 39, 138, 1)',
};

export const HUMIDITY_RGBA_COLOR = {
  GREEN: 'rgba(0, 168, 107, 1)',
  YELLOW: 'rgba(255, 225, 53, 0.8)',
  ORANGE: 'rgba(255, 153, 68, 1)',
  RED: 'rgba(210, 20, 4, 1)',
};

export const CARBON_RGBA_COLOR = {
  GREEN: 'rgba(0, 168, 107, 1)',
  YELLOW: 'rgba(255, 225, 53, 0.8)',
  ORANGE: 'rgba(255, 153, 68, 1)',
  RED: 'rgba(210, 20, 4, 1)',
};

export const NOISE_RGBA_COLOR = {
  GREEN: 'rgba(0, 168, 107, 1)',
  YELLOW: 'rgba(255, 225, 53, 0.8)',
  ORANGE: 'rgba(255, 153, 68, 1)',
  RED: 'rgba(210, 20, 4, 1)',
};

export const DEVICE_MARKER_COLOR = {
  MERAKI: '#10b981',
  VERKADA: '#3b82f6',
  BUTLR: '#FF3D00',
  WEBEX: '#22D3EE',
  ARUBA: '#FF8000',
};

export const MEETING_ROOM_STATUS_BG_COLOR = {
  OCCUPIED_BG_COLOR: '#c42200',
  RESERVED_BG_COLOR: '#cea000',
  NOT_FINISH_BG_COLOR: 'gray',
};

export const CHAT_TOOL_PRESENCE_COLOR = {
  GREEN: '#008000',
  RED: '#ff0000',
  YELLOW: '#e5a300',
  GRAY: '#c0c0c0',
};

export const THRESHOLDS_RGBA_COLOR = {
  LIGHT_GREEN: 'rgba(0, 149, 24, 1)',
  GREEN: 'rgba(165, 244, 54, 1)',
  YELLOW: 'rgba(244, 236, 54, 1)',
  ORANGE: 'rgba(244, 145, 54, 1)',
  RED: 'rgba(244, 67, 54, 1)',
};
