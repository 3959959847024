import AreaMarker from '@/components/CustomComponent/AreaMarker';
import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import ImageAreaMarker from '@/components/CustomComponent/ReactImageAreaMarker';
import { COMMON_TEXT } from '@/helpers/common-text';
import { MEMORY_STORE, QUERY_STRING_STORE } from '@/helpers/constants';
import {
  calculateRectangle,
  transformCoordinateBottomLeftToTopLeft,
} from '@/helpers/utility';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getPeopleCountsAreaByFloorId,
  fetchPeopleCountFormat,
  fetchPeopleCountThresholds,
} from './query-request';
import { isEqual } from 'lodash';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class PeopleCounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      isLoading: false,
      floorMapUrl: '',
      floorMapError: false,
      lastUpdateTs: '',
      isPeopleFormatCount: false,
      areaLegend: [],
    };
    this._isMounted = false; // Flag to track the mounted status
    this.tooltipRef = React.createRef();
  }

  validateFloorMapUrl(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(url);
      img.onerror = () => reject(url);
      img.src = url;
    });
  }

  getFloorMapUrl = floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    const floorMapUrl = floor ? floor.floor_map : '';
    return floorMapUrl;
  };

  setFloorMap = async floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    const floorMapUrl = floor ? floor.floor_map : '';
    try {
      await this.validateFloorMapUrl(floorMapUrl);
      if (this._isMounted) {
        this.setState({ floorMapError: false, floorMapUrl: floorMapUrl });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ floorMapError: true, floorMapUrl: '' });
      }
    }
  };

  componentDidMount() {
    this._isMounted = true; // Set the flag to true when the component mounts
    const { isSignageMode, signageModeData, isPeopleFormatCount, areaLegend } =
      this.props;
    if (isSignageMode) {
      const { data, floor } = signageModeData;
      const { dataPeopleCounts } = data;
      let lastUpdateTs = dataPeopleCounts?.[0]?.timestamp ?? '';
      if (lastUpdateTs) {
        lastUpdateTs = format(new Date(lastUpdateTs), 'MM月dd日 - HH:mm');
      }
      this.setState({
        data: dataPeopleCounts,
        floor,
        lastUpdateTs,
        floorMapUrl: this.getFloorMapUrl(floor),
        isPeopleFormatCount,
        areaLegend,
      });
    } else {
      const { querystring } = this.props;
      const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      if (floor) {
        this.setFloorMap(floor);
        this.setState({ floor }, () => {
          this.fetchDataByFloorId();
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false; // Set the flag to false when the component unmounts
  }

  componentDidUpdate(prevProps) {
    const {
      querystring,
      isSignageMode,
      signageModeData,
      isPeopleFormatCount,
      areaLegend,
    } = this.props;
    const {
      querystring: prevQuerystring,
      signageModeData: prevSignageModeData,
    } = prevProps;
    if (isSignageMode) {
      const { data, floor } = signageModeData;
      const { data: prevData } = prevSignageModeData;
      const { dataPeopleCounts } = data;
      const isSignageModeDataChanged = !isEqual(data, prevData);
      if (isSignageModeDataChanged) {
        let lastUpdateTs = dataPeopleCounts[0]?.timestamp ?? '';
        if (lastUpdateTs) {
          lastUpdateTs = format(new Date(lastUpdateTs), 'MM月dd日 - HH:mm');
        }
        this.setState({
          data: dataPeopleCounts,
          floor,
          floorMapUrl: this.getFloorMapUrl(floor),
          lastUpdateTs,
          isPeopleFormatCount,
          areaLegend,
        });
      }
    } else {
      const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
      const prevFloor =
        prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
      const prevTenant =
        prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
      const isFloorChanged = floor !== prevFloor;
      const isTenantChanged = tenant !== prevTenant;
      if (isFloorChanged || isTenantChanged) {
        this.setFloorMap(floor);
        this.setState({ floor, tenant }, () => {
          this.fetchDataByFloorId();
        });
      }
    }
  }

  fetchDataByFloorId = async () => {
    const { floor, tenant } = this.state;
    this.setState({ isLoading: true });
    let dataFloorMap = await getPeopleCountsAreaByFloorId({
      floorId: floor,
    });

    let isPeopleFormatCount = false;
    let areaLegend = [];
    const dataPeopleCountFormat = await fetchPeopleCountFormat({
      tenantId: tenant,
    });
    const peopleCountThresholds = await fetchPeopleCountThresholds({
      tenantId: tenant,
    });

    if (
      dataPeopleCountFormat &&
      dataPeopleCountFormat[0]?.people_counts_format === 'count'
    ) {
      isPeopleFormatCount = true;
    }

    if (peopleCountThresholds && peopleCountThresholds[0]) {
      const { good, fair, bad, poor } = peopleCountThresholds[0];
      areaLegend = [
        {
          label: COMMON_TEXT.EMPTY,
          color: 'rgba(0, 168, 107, 0.6)',
          value: `~ ${good - 1}%`,
        },
        {
          label: '',
          color: 'rgba(152, 214, 58, 0.6)',
          value: `${good}% ~ ${fair - 1}%`,
        },
        {
          label: '',
          color: 'rgba(255, 225, 53, 0.6)',
          value: `${fair}% ~ ${bad - 1}%`,
        },
        {
          label: '',
          color: 'rgba(255, 153, 68, 0.6)',
          value: `${bad}% ~ ${poor - 1}%`,
        },
        {
          label: COMMON_TEXT.CROWDED,
          color: 'rgba(210, 20, 4, 0.6)',
          value: `${poor}% ~`,
        },
      ];
    }

    if (!dataFloorMap) {
      dataFloorMap = [];
    }

    const updatedFloorMapData = dataFloorMap.map(item => {
      const { position_x, position_y, position_x1, position_y1 } = item;
      const { pointC, width, height } = calculateRectangle({
        positionX: position_x,
        positionY: position_y,
        positionX1: position_x1,
        positionY1: position_y1,
      });
      const { x, y } = transformCoordinateBottomLeftToTopLeft({
        x: pointC.x,
        y: pointC.y,
      });
      item.rect = {
        top: y,
        left: x,
        width,
        height,
      };
      return item;
    });

    let lastUpdateTs = updatedFloorMapData[0]?.timestamp ?? '';
    if (lastUpdateTs) {
      lastUpdateTs = format(new Date(lastUpdateTs), 'MM月dd日 - HH:mm');
    }
    this.setState({
      data: updatedFloorMapData,
      isLoading: false,
      lastUpdateTs,
      isPeopleFormatCount,
      areaLegend,
    });
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderRefreshButton() {
    const { isLoading, floor } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        loading={isLoading}
        disabled={!floor}
        className="refresh-button has-shadow"
        severity="info"
        size="small"
        onClick={() => this.fetchDataByFloorId()}
      />
    );
  }

  AreaMarkerViewOnly = props => {
    const { isPeopleFormatCount } = this.state;
    return (
      <AreaMarker
        {...props}
        isViewOnly={true}
        isPeopleFormatCount={isPeopleFormatCount}
      ></AreaMarker>
    );
  };

  infoButtonOnClick = e => {
    if (this.tooltipRef.current) {
      this.tooltipRef.current.show(e);
    }
  };

  renderLegendContent = areaLegend => {
    if (!areaLegend) {
      return;
    }
    return (
      <div className="legend-container">
        <div className="legend-header">
          <span>{COMMON_TEXT.PEOPLE_COUNTS_LEGEND}</span>
        </div>
        <div className="legend-content">
          <div className="legend-header-t">
            {areaLegend.map((item, index) => {
              const { color, label } = item;
              const isHaveLabel = label ? true : false;
              const marginTop =
                index === 1 ? '28px' : index === 2 ? '14px' : '';

              return (
                <div className="item-row" key={`legend-item-row-${index}`}>
                  <div className="item-name-container">
                    <div
                      className="item-color"
                      style={{ backgroundColor: color }}
                    ></div>
                    {isHaveLabel && (
                      <div className="item-name">
                        <span>{label}</span>
                      </div>
                    )}
                    {!isHaveLabel && (
                      <div>
                        <div
                          className="menu-block1"
                          style={{
                            backgroundColor: color,
                            marginTop: marginTop,
                          }}
                        ></div>
                        <div
                          className="menu-block2"
                          style={{ backgroundColor: color }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="legend-header-t">
            {areaLegend.map((item, index) => {
              const { value } = item;
              return (
                <div className="item-row" key={`legend-item-row-${index}`}>
                  <div className="item-value">
                    <span>{value}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      data: floorMapData,
      isLoading,
      floorMapUrl,
      floorMapError,
      lastUpdateTs,
      areaLegend,
    } = this.state;
    const { isSignageMode, CurrentTimeComponent } = this.props;

    return (
      <div
        className={`config-container custom-config-container people-counts ${
          isSignageMode ? 'signage-mode' : ''
        }`}
      >
        <div className="config-title-container">
          <div className="text-left">
            <div className="title-text">{COMMON_TEXT.PEOPLE_COUNTS}</div>
            <Tooltip
              ref={this.tooltipRef}
              target={`.custom-legend-button`}
              position="right"
              className={`${
                isSignageMode
                  ? 'custom-legend-tooltip-signage-people-count'
                  : 'custom-legend-tooltip-people-count'
              }`}
              event="hover"
            >
              <div className="data-container-legend">
                {this.renderLegendContent(areaLegend)}
              </div>
            </Tooltip>
            <Button
              type="button"
              className={`custom-legend-button custom-legend-button}`}
              severity="secondary"
              text
              onClick={e => this.infoButtonOnClick(e)}
            >
              <i className="pi pi-info-circle"></i>
            </Button>
            <div className="last-updated-ts">
              {COMMON_TEXT.LAST_UPDATE_TS}: {lastUpdateTs}
            </div>
          </div>
          {isSignageMode ? (
            <div className="custom-current-container">
              <CurrentTimeComponent />
            </div>
          ) : (
            <div className="grid grid-nogutter align-items-center justify-content-end">
              {this.renderRefreshButton()}
            </div>
          )}
        </div>
        <div className="config-content">
          {this.renderMessageError() || (
            <>
              <div className="main-container">
                {isSignageMode && (
                  <div className="data-container-legend-signage">
                    {this.renderLegendContent(areaLegend)}
                  </div>
                )}
                <div className="image-container">
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <ImageAreaMarker
                        bufferLeft={0}
                        bufferTop={0}
                        src={floorMapUrl}
                        areaMarkerComponent={this.AreaMarkerViewOnly}
                        markers={[]}
                        areaMarkers={floorMapError ? [] : floorMapData || []}
                        isDisableDragMarker={false}
                        extraClass={isSignageMode ? 'custom-image-marker' : ''}
                      />
                      <>
                        {floorMapError && (
                          <Message
                            severity="error"
                            text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
                          />
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

PeopleCounts.propTypes = {};
export default PeopleCounts;
