import { COMMON_TEXT } from '@/helpers/common-text';
import {
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  USER_ID_KEY,
} from '@/helpers/constants';
import { generateRandomString } from '@/helpers/utility';
import AuthToken from '@/services/auth-token';
import { cloneDeep } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchSignageComponents,
  fetchSignageComponentNotice,
} from './queryRequest';
import { TabPanel, TabView } from 'primereact/tabview';
import { Message } from 'primereact/message';
import SignageComponentNotice from './SignageComponentNotice';
import SignageComponentOverview from './SignageComponentOverview';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class SignageComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      signageComponentsList: [],
      notice: '',
      tabActiveIndex: 0,
    };
    this.toastRef = React.createRef();
    this.authToken = new AuthToken();
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getSignageComponentData();
      });
    }
    const { sessionStore } = this.props;
    const sessionExecutionList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (sessionExecutionList && sessionExecutionList.length > 0) {
      this.setState({ isActionUpdating: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant: tenant }, () => {
        this.getSignageComponentData();
      });
    }
  }

  componentWillUnmount() {}

  getSignageComponentData = async () => {
    const { tenant } = this.state;
    this.setState({ isLoading: true });
    const userId = sessionStorage.getItem(USER_ID_KEY);
    let signageComponentsList = await fetchSignageComponents({
      tenantId: tenant,
      userId: userId,
    });
    const signageComponentNotice = await fetchSignageComponentNotice({
      tenantId: tenant,
    });
    signageComponentsList = signageComponentsList || [];
    signageComponentsList.forEach(item => {
      item.rowId = generateRandomString(10);
      item.initData = cloneDeep(item);
    });
    signageComponentsList.sort((a, b) => a.display_order - b.display_order);

    const notice =
      signageComponentNotice &&
      signageComponentNotice.length > 0 &&
      signageComponentNotice[0].notice
        ? signageComponentNotice[0].notice
        : '';

    this.setState({
      signageComponentsList,
      notice,
      isLoading: false,
    });
  };

  renderMessageError = () => {
    const { tenant } = this.state;
    if (!tenant) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  updateOverview = ({ isUpdate }) => {
    if (isUpdate) {
      this.getSignageComponentData();
    }
  };

  render() {
    const { isLoading, signageComponentsList, notice, tabActiveIndex } =
      this.state;

    return (
      <div className="config-container custom-config-container signage-components-container">
        <div className="config-title-signage">
          <div className="title-text">{COMMON_TEXT.SIGNAGE_MODE}</div>
        </div>
        <div className="config-content">
          {this.renderMessageError() || (
            <>
              <TabView
                className=""
                activeIndex={tabActiveIndex}
                renderActiveOnly={true}
                onTabChange={e => this.setState({ tabActiveIndex: e.index })}
                style={{ width: '100%' }}
              >
                <TabPanel
                  header={COMMON_TEXT.COMPONENT}
                  headerTemplate={this.tabHeaderTemplate}
                >
                  <div className="m-0">
                    {tabActiveIndex === 0 && (
                      <SignageComponentOverview
                        isLoading={isLoading}
                        notice={notice}
                        signageComponentsList={signageComponentsList}
                        updateNotice={this.updateOverview}
                      />
                    )}
                  </div>
                </TabPanel>
                <TabPanel
                  header={COMMON_TEXT.TITLE_NOTICE}
                  headerTemplate={this.tabHeaderTemplate}
                >
                  <div className="m-0">
                    {tabActiveIndex === 1 && (
                      <SignageComponentNotice
                        notice={notice}
                        isLoading={isLoading}
                        updateNotice={this.updateOverview}
                      />
                    )}
                  </div>
                </TabPanel>
              </TabView>
            </>
          )}
        </div>
      </div>
    );
  }
}

SignageComponents.propTypes = {};
SignageComponents.whyDidYouRender = true;
export default SignageComponents;
