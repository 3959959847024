import { COMMON_TEXT } from '@/helpers/common-text';
import {
  EXECUTION_ID_TIMEOUT,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
  THRESHOLDS_RGBA_COLOR,
} from '@/helpers/constants';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { isEmpty, toNumber } from 'lodash';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import { getConfigurationUrlWithParam } from 'src/helpers/utility';
import AuthToken from 'src/services/auth-token';
import {
  fetchPeopleCountFormat,
  fetchPeopleCountThresholds,
} from './query-request';

const PEOPLE_COUNT_TYPE = {
  COUNTS: 'count',
  RATE: 'rate',
};

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class PeopleCountSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      isUpdating: false,

      peopleCountFormat: '',
      inputErrorFields: new Set(),
      thresholds: {},
    };
    this.toastRef = React.createRef();
    this.authToken = new AuthToken();

    this.peopleOptions = [
      {
        label: COMMON_TEXT.COUNTS,
        value: PEOPLE_COUNT_TYPE.COUNTS,
      },
      {
        label: COMMON_TEXT.RATE,
        value: PEOPLE_COUNT_TYPE.RATE,
      },
    ];

    this.rateOptions = [
      {
        label: 'Excellent',
        color: THRESHOLDS_RGBA_COLOR.LIGHT_GREEN,
        noInput: true,
        position: 'center',
        maxWidth: '160px',
      },
      {
        label: 'Good',
        color: THRESHOLDS_RGBA_COLOR.GREEN,
        field: 'good',
        position: 'right',
        maxWidth: '120px',
      },
      {
        label: 'Fair',
        color: THRESHOLDS_RGBA_COLOR.YELLOW,
        field: 'fair',
        position: 'right',
        maxWidth: '120px',
      },
      {
        label: 'Bad',
        color: THRESHOLDS_RGBA_COLOR.ORANGE,
        field: 'bad',
        position: 'right',
        maxWidth: '120px',
      },
      {
        label: 'Poor',
        color: THRESHOLDS_RGBA_COLOR.RED,
        field: 'poor',
        position: 'right',
        maxWidth: '120px',
      },
    ];
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.getData();
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button has-shadow"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getData = async () => {
    const { tenant } = this.state;
    this.setState({ isLoading: true });

    const dataPeopleCountFormat = await fetchPeopleCountFormat({
      tenantId: tenant,
    });
    const peopleCountThresholds = await fetchPeopleCountThresholds({
      tenantId: tenant,
    });

    this.setState({
      peopleCountFormat: dataPeopleCountFormat?.[0]?.people_counts_format || '',
      thresholds: peopleCountThresholds?.[0] || {},
      isLoading: false,
    });
  };

  updateInfo = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.PEOPLE_COUNTS,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE PEOPLE COUNTS SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE PEOPLE COUNTS SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    const { isSuccess, executionId } = await this.updateInfo({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    }

    if (!isSuccess) {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  showMessage = ({ detail }) => {
    this.toastRef.current.show({
      severity: 'info',
      detail,
      life: 3000,
      closable: true,
    });
  };

  updateButtonOnClicked = () => {
    const { peopleCountFormat, thresholds, inputErrorFields } = this.state;

    let isEmpty = false;
    const fields = ['good', 'fair', 'bad', 'poor'];
    const emptyFields = fields.filter(field => !thresholds[field]);

    if (!peopleCountFormat) {
      isEmpty = true;
      inputErrorFields.add('peopleCountFormat');
    }

    if (emptyFields.length > 0) {
      isEmpty = true;
      emptyFields.forEach(field => inputErrorFields.add(field));
    }

    if (isEmpty) {
      this.setState({ inputErrorFields: new Set(inputErrorFields) });
      return;
    }

    const updatePayload = {
      value: {
        people_counts_format: peopleCountFormat,
        thresholds,
      },
    };
    if (updatePayload.value && updatePayload.value.people_counts_format) {
      this.updateData({ updatePayLoad: updatePayload });
    }
  };

  renderSettingContent() {
    const {
      isLoading,
      peopleCountFormat,
      thresholds,
      inputErrorFields,
      isUpdating,
    } = this.state;

    const hasError = inputErrorFields.size > 0;

    const handleChangePeopleCountFormat = e => {
      let val = e.target.value;
      inputErrorFields.delete('peopleCountFormat');
      this.setState({
        peopleCountFormat: val,
        inputErrorFields: new Set(inputErrorFields),
      });
    };

    const validateInput = (value, previousValue) => {
      const numValue = parseInt(value);
      if (isNaN(numValue) || numValue <= 0) {
        return true;
      }
      if (previousValue && numValue <= previousValue) {
        return true;
      }
      return false;
    };

    const updateInputValue = (newValue, option) => {
      const { thresholds, inputErrorFields } = this.state;

      const previousValue =
        option.field === 'good'
          ? 0
          : option.field === 'fair'
          ? thresholds.good
          : option.field === 'bad'
          ? thresholds.fair
          : thresholds.bad;

      const newThresholds = { ...thresholds, [option.field]: newValue };
      const errorMsg = validateInput(newValue, previousValue);

      if (errorMsg) {
        inputErrorFields.add(option.field);
        this.setState({
          inputErrorFields: new Set(inputErrorFields),
          thresholds: newThresholds,
        });
        return;
      }

      inputErrorFields.delete(option.field);
      this.setState(
        {
          thresholds: newThresholds,
          inputErrorFields: new Set(inputErrorFields),
        },
        () => {
          checkNextField(option.field, newThresholds);
        }
      );
    };

    const checkNextField = (field, newThresholds) => {
      const { inputErrorFields } = this.state;
      let nextField = '';

      if (field === 'good') {
        nextField = 'fair';
      } else if (field === 'fair') {
        nextField = 'bad';
      } else if (field === 'bad') {
        nextField = 'poor';
      }

      if (nextField) {
        const errorMsg = validateInput(
          newThresholds[nextField],
          newThresholds[field]
        );

        if (errorMsg) {
          inputErrorFields.add(nextField);
          this.setState({
            inputErrorFields: new Set(inputErrorFields),
          });
          return;
        }

        inputErrorFields.delete(nextField);
        this.setState({
          inputErrorFields: new Set(inputErrorFields),
        });
      }
    };

    const inputNumberOnChanged = (e, option) => {
      let value = `${e.value}`;
      value = value.replace(/[^0-9]/g, '');
      let newValue = null;
      if (value) {
        newValue = toNumber(value);
      }
      updateInputValue(newValue, option);
    };

    const inputNumberOnInput = (e, option) => {
      let value = e.target?.value;
      if (isEmpty(value)) {
        const errorField = option.field;
        const newErrorFields = new Set();
        newErrorFields.add(errorField);
        this.setState({ inputErrorFields: newErrorFields });
        return;
      }
      value = value.replace(/[^0-9]/g, '');
      let newValue = null;
      if (value) {
        newValue = toNumber(value);
      }
      updateInputValue(newValue, option);
    };

    const invalidInputClassName = field => {
      return inputErrorFields.has(field) ? 'p-invalid' : '';
    };

    const getFormErrorMessage = field => {
      return invalidInputClassName(field) ? (
        <span className="validation-error-message">{COMMON_TEXT.REQUIRED}</span>
      ) : (
        <></>
      );
    };

    return (
      <div>
        {isLoading ? (
          <div className="ml-auto mr-auto">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="people-count-format-row-container people-count-format">
              <div className="left-title">
                <span>{COMMON_TEXT.PEOPLE_COUNT_SELECTED}</span>
              </div>
              <div className="right-content">
                <div className="input-text-field-container">
                  <Dropdown
                    id={`peopleCountFormat`}
                    value={peopleCountFormat}
                    options={this.peopleOptions}
                    onChange={handleChangePeopleCountFormat}
                    className={invalidInputClassName('peopleCountFormat')}
                  />
                  {getFormErrorMessage('peopleCountFormat', inputErrorFields)}
                </div>
              </div>
            </div>
            <div className="rate-setting-container">
              <div className="rate-setting-item">
                <div className="rate-setting-label">
                  {COMMON_TEXT.THRESHOLD}
                </div>
                <div className="rate-setting-content">
                  {this.rateOptions.map((option, index) => (
                    <div
                      className="rate-setting-content-item"
                      key={`temp-item-${index}`}
                      style={{
                        maxWidth: option.maxWidth,
                      }}
                    >
                      <div
                        className={`label label-${option.position}`}
                        style={{
                          marginRight: option.noInput ? '50px' : '',
                        }}
                      >
                        <span>{option.label}</span>
                        {inputErrorFields.has(option.field) && (
                          <span className="error-label">
                            {thresholds[option.field]
                              ? COMMON_TEXT.PLEASE_INPUT_VALID_NUMBER
                              : COMMON_TEXT.REQUIRED}
                          </span>
                        )}
                      </div>
                      <div
                        className={`value-input value-input-${option.position}`}
                      >
                        {!option.noInput ? (
                          <InputNumber
                            maxFractionDigits={1}
                            value={thresholds[option.field]}
                            inputClassName={invalidInputClassName(option.field)}
                            mode="decimal"
                            allowEmpty={true}
                            onChange={e => inputNumberOnChanged(e, option)}
                            onInput={e => inputNumberOnInput(e, option)}
                            onKeyDown={e => {
                              if (e.key === '.') {
                                e.preventDefault();
                              }
                            }}
                          ></InputNumber>
                        ) : (
                          <div
                            style={{
                              marginRight: option.noInput ? '50px' : '',
                            }}
                          >
                            <span>0</span>
                          </div>
                        )}
                      </div>
                      <div
                        className={`color-line`}
                        style={{
                          backgroundColor: option.color,
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="button-container">
              <Button
                type="submit"
                severity="info"
                label="保存"
                disabled={hasError}
                loading={isUpdating}
                className="submit-button has-shadow"
                onClick={this.updateButtonOnClicked}
              />
            </div>
          </>
        )}
      </div>
    );
  }

  render() {
    const { tenant } = this.state;

    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <div className="config-container custom-config-container people-count-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">{COMMON_TEXT.PEOPLE_COUNTS}</div>
            </div>
            <div className="grid grid-nogutter align-items-center justify-content-end">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">
            <div className="setting-content">
              {tenant ? (
                this.renderSettingContent()
              ) : (
                <Message
                  severity="error"
                  text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PeopleCountSetting;
