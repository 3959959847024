import {
  eachDayOfInterval,
  format,
  isAfter,
  isBefore,
  subMonths,
  subWeeks,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const extractValueFromQueryResponse = response => {
  const results = response.results;
  const queryParams = response.query_params;
  const fields = queryParams.data.fields;

  const output = results.map(result => {
    const data = result.data;
    const extractedValues = {};

    for (const field in fields) {
      if (data.hasOwnProperty(field)) {
        const dataFieldKeys = Object.keys(data[field]);
        if (dataFieldKeys.length > 0) {
          const dataKey = dataFieldKeys[0];
          const dataValue = data[field]?.[dataKey]?.[0]?.['value'] ?? null;
          if (field === 'timestamp') {
            extractedValues[field] =
              fixDateStringForAllBrowser(dataValue) ?? null;
          } else {
            extractedValues[field] = dataValue;
          }
        }
      }
    }
    return extractedValues;
  });

  return output;
};

export async function imageUrlToBase64(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      // replace the data type in the data URL with an empty string
      resolve(reader.result.replace(/^data:.+;base64,/, ''));
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export const getRandomHexColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const sortObjectKeys = obj => {
  const sortedKeys = Object.keys(obj).sort();
  const sortedObj = {};
  for (const key of sortedKeys) {
    sortedObj[key] = obj[key];
  }
  return sortedObj;
};

export const convertArrayToObject = (arr, keyField) => {
  if (!arr) {
    return {};
  }
  return arr.reduce((acc, item) => {
    const keyFieldVal = item[keyField];
    if (!acc[keyFieldVal]) {
      acc[keyFieldVal] = [];
    }
    acc[keyFieldVal].push(item);
    return acc;
  }, {});
};

export function formatPercentage(number) {
  if (!number) {
    return '0%';
  }
  return `${(number * 100).toFixed(2)}%`;
}

export function convertDecimalToPercentage(number) {
  if (!number) {
    return 0;
  }
  number = +number;
  return parseFloat((number * 100).toFixed(2));
}

export function generateRandomString(length) {
  // Define the character set
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  // Generate a random string of the specified length
  let randomString = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset[randomIndex];
  }

  // Return the random string
  return randomString;
}

export function randomStringWithSymbol(length) {
  // Define the character set
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';

  // Generate a random string of the specified length
  let randomString = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset[randomIndex];
  }

  // Return the random string
  return randomString;
}

export function isObjectEmpty(obj) {
  if (!obj) {
    return false;
  }
  return Object.keys(obj).length === 0;
}

export function sortByTimestamp(array, prop) {
  const dateCache = {};
  const getDate = timestamp => {
    if (!dateCache[timestamp]) {
      dateCache[timestamp] = new Date(timestamp);
    }
    return dateCache[timestamp];
  };

  return array.sort((a, b) => getDate(a[prop]) - getDate(b[prop]));
}

export function calculateRectangle({ positionX, positionY, positionX1, positionY1 }) {
  // positionX < positionX1, positionY < positionY1
  // Calculate the missing points assuming they form a rectangle and A, B are diagonal
  // C - B
  // |   |
  // A - D
  const pointA = { x: positionX, y: positionY };
  const pointB = { x: positionX1, y: positionY1 };
  const pointC = { x: positionX, y: positionY1 };
  const pointD = { x: positionX1, y: positionY };

  // Calculate width and height (percentage)
  // The width and height are the absolute differences in the x and y coordinates respectively
  const width = Math.abs(positionX1 - positionX) * 100;
  const height = Math.abs(positionY1 - positionY) * 100;

  return {
    pointA: pointA,
    pointB: pointB,
    pointC: pointC,
    pointD: pointD,
    width: width,
    height: height
  };
}

export function transformRectangleToAreaPosition({ top, left, width, height }) {
  // top, left, width, height are in percentage
  // Calculate the missing points assuming they form a rectangle and A, B are diagonal
  // A - D
  // |   |
  // C - B
  // const pointA = { x: left, y: top };
  // const pointB = { x: left + width, y: top + height };
  const pointC = { x: left, y: top + height };
  const pointD = { x: left + width, y: top };
  const positionX = pointC.x;
  const positionY = 100 - pointC.y;
  const positionX1 = pointD.x;
  const positionY1 = 100 - pointD.y;
  return {
    position_x: positionX / 100,
    position_y: positionY / 100,
    position_x1: positionX1 / 100,
    position_y1: positionY1 / 100,
  };
}

export function transformCoordinateBottomLeftToTopLeft({ x, y }) {
  return {
    x: x * 100,
    y: (1 - y) * 100
  };
}

export function transformPositionToMarkerCoordinate(response) {
  const { x, y } = response;
  const marker = {
    top: x && y ? (1 - y) * 100 : - 2, // convert from y to top
    left: x && y ? x * 100 : 50, // convert from x to left
  };
  return marker;
}

export function getCurrentFullDateFormatted(formatString) {
  if (formatString) {
    return format(new Date(), formatString);
  }
  return format(new Date(), 'yyyy-MM-dd HH:mm:ss');
}

export function getCurrentFullDateFormattedInTimeZone(
  formatString,
  timezoneString
) {
  if (!timezoneString) {
    timezoneString = 'Asia/Tokyo';
  }
  if (!formatString) {
    formatString = 'yyyy-MM-dd HH:mm:ss';
  }
  const currentTime = new Date();
  const timezoneTime = utcToZonedTime(currentTime, timezoneString);
  const formattedTime = format(timezoneTime, formatString, {
    timeZone: timezoneString,
  });
  return formattedTime;
}

export function extractTimeFromDateString(dateString) {
  if (!dateString) {
    return {
      value: '',
      isDateStringFormat: false,
    };
  }
  const regex = /^(\d{4}[-/]\d{2}[-/]\d{2}) (\d{2}:\d{2}):\d{2}$/;
  const match = dateString.match(regex);

  if (match) {
    const time = match[2];
    return {
      value: time,
      isDateStringFormat: true,
    };
  }

  return {
    value: dateString,
    isDateStringFormat: false,
  };
}

export function formatNumberWithCommas(input) {
  if (typeof input === 'number' || /^(\d+\.?\d*)$/.test(input)) {
    const number = typeof input === 'number' ? input : parseFloat(input);

    // Convert the number to a string
    let strNumber = number.toString();

    // Split the number into integer and fractional parts
    let [integerPart, fractionalPart] = strNumber.split('.');

    // Format the integer part with commas
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // If there was a fractional part, add it back
    if (fractionalPart !== undefined) {
      strNumber = `${integerPart}.${fractionalPart}`;
    } else {
      strNumber = integerPart;
    }

    return strNumber;
  }

  return input;
}

export function convertToCamelCase(text) {
  return text
    .split('-') // split the string into an array of words
    .map((word, index) => {
      // for each word, if it's the first word, return it as is;
      // otherwise, return it with the first letter capitalized
      if (index === 0) {
        return word;
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(''); // join the array of words back into a single string
}

export function checkListChanges(originalList, newList, keyField) {
  if (!originalList || !newList) {
    return {
      elementsChanged: originalList !== newList,
      orderChanged: false,
    };
  }
  if (originalList.length !== newList.length) {
    return {
      elementsChanged: true,
      orderChanged: false,
    };
  }

  // Copy lists to avoid modifying the original lists
  let originalListCopy = [...originalList];
  let newListCopy = [...newList];

  // Check if the elements in the list have changed
  let elementsChanged = false;
  originalListCopy.sort((a, b) => a[keyField]?.localeCompare(b[keyField]));
  newListCopy.sort((a, b) => a[keyField]?.localeCompare(b[keyField]));

  for (let i = 0; i < originalListCopy.length; i++) {
    if (originalListCopy[i][keyField] !== newListCopy[i][keyField]) {
      elementsChanged = true;
      break;
    }
  }

  // Check if the order of the list has changed
  let orderChanged = false;
  for (let i = 0; i < originalList.length; i++) {
    if (originalList[i][keyField] !== newList[i][keyField]) {
      orderChanged = true;
      break;
    }
  }

  return { elementsChanged, orderChanged };
}

export function sortArrayByOtherArray(array, otherArray, keyField) {
  return array.sort((a, b) => {
    const indexA = otherArray[a[keyField]];
    const indexB = otherArray[b[keyField]];
    // If both indices are defined, sort normally.
    if (indexA !== undefined && indexB !== undefined) {
      return indexA - indexB > 0 ? 1 : -1;
    }

    // If one index is undefined, move it to the end.
    if (indexA === undefined && indexB !== undefined) {
      return 1;
    }
    if (indexA !== undefined && indexB === undefined) {
      return -1;
    }

    // If both indices are undefined, leave them as they are.
    return 0;
  });
}

export function sortObjectOrArrayByDeviceIdList({
  deviceIdList,
  dataObject,
  sortKey,
}) {
  if (
    dataObject &&
    typeof dataObject === 'object' &&
    !Array.isArray(dataObject)
  ) {
    let sortedDataList = {};

    // Iterate over deviceIdList and add to sortedDataList in the correct order
    for (const deviceId of deviceIdList) {
      if (dataObject.hasOwnProperty(deviceId)) {
        sortedDataList[deviceId] = dataObject[deviceId];
      }
    }

    return { sortedDataList };
  } else if (
    dataObject &&
    typeof dataObject === 'object' &&
    Array.isArray(dataObject)
  ) {
    const orderMapping = deviceIdList.reduce((acc, deviceId, index) => {
      acc[deviceId] = index;
      return acc;
    }, {});
    if (dataObject?.length > 0) {
      const sorted = sortArrayByOtherArray(dataObject, orderMapping, sortKey);
      return {
        sortedDataList: sorted,
      };
    }
    return { sortedDataList: [] };
  }
  return { sortedDataList: [] };
}

export function isOverlap(event1, event2) {
  let start1 = new Date(event1.start_time);
  let end1 = new Date(event1.end_time);
  let start2 = new Date(event2.start_time);
  let end2 = new Date(event2.end_time);

  return isBefore(start1, end2) && isAfter(end1, start2);
}

export function timestampTickList() {
  let tickList = [];
  for (let i = 0; i < 24; i++) {
    // padStart is used to add a 0 before single digit hours
    let hour = i.toString().padStart(2, '0');
    tickList.push(`${hour}:00`);
  }

  return tickList;
}

export function dayTickListFromToday({ isWeekly, isMonthly }) {
  const currentDate = new Date();
  let daysAgo;
  if (isWeekly) {
    daysAgo = subWeeks(currentDate, 1);
  } else if (isMonthly) {
    daysAgo = subMonths(currentDate, 1);
  }
  if (!daysAgo) {
    return [];
  }
  const datesInDaysAgo = eachDayOfInterval({
    start: daysAgo,
    end: currentDate,
  });
  return datesInDaysAgo.map(date => {
    return format(date, 'yyyy-MM-dd');
  });
}

export function getConfigurationUrlWithParam({ querystring }) {
  let qString = '';
  if (querystring) {
    const querystringKeys = Object.keys(querystring);
    for (const queryStrKey of querystringKeys) {
      if (
        queryStrKey &&
        queryStrKey.includes('select-') &&
        querystring[queryStrKey]
      ) {
        qString += `${queryStrKey}=[${querystring[queryStrKey]}]&`;
      }
    }
  }
  return `#configuration?${qString}`;
}

export function getRandomNumber900000to999999() {
  return Math.floor(Math.random() * 100000) + 900000;
}

export function getRandomNumberRange({ min, max }) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function fixDateStringForAllBrowser(dateString) {
  // fixDateForAllBrowsers is used to convert date string from yyyy-mm-dd to yyyy/mm/dd
  if (!dateString) {
    return null;
  }
  return dateString.replace(/-/g, '/');
}

export const percentToPixel = (percent, contextSize) => {
  const percentValue = parseFloat(percent);
  const pixelValue = (percentValue / 100) * contextSize;
  const roundedPixelValue = Math.round(pixelValue);
  return roundedPixelValue;
};

export const pixelToPercent = (pixels, contextSize) => {
  const pixelValue = parseFloat(pixels);
  const percentageValue = (pixelValue / contextSize) * 100;
  return percentageValue;
};

/**
 * Safely parse components string and get first value
 * @param {string} componentsString - String representation of components array
 * @param {any} defaultValue - Default value to return if parsing fails
 * @return {any} First value from the array or default value
 */
export const getFirstComponentValue = (componentsString, defaultValue = null) => {
  try {
    // Check if input is not a string
    if (typeof componentsString !== 'string') {
      return defaultValue;
    }

    // Check for empty string
    if (!componentsString || componentsString.trim() === '') {
      return defaultValue;
    }

    // Parse string to array
    const componentsArray = JSON.parse(componentsString);
    
    // Check if the parsed result is an array
    if (!Array.isArray(componentsArray)) {
      return defaultValue;
    }
    
    // Check if the array is empty
    if (componentsArray.length === 0) {
      return defaultValue;
    }
    // Get the first value
    return componentsArray[0];
    
  } catch (error) {
    console.error('Error parsing components string:', error);
    return defaultValue;
  }
}
