/**
 *
 * SignageComponentOverview
 *
 */

import SignageComponentOverview from './SignageComponentOverview.jsx';
import './SignageComponentOverview.less';

export default SignageComponentOverview;
